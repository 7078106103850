import React, { useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import VerticalBar from "../../../../../Components/VerticalBar/VerticalBar";
import { dummy_sections, graph_filters } from "../data";
import { RiArrowDropDownLine } from "react-icons/ri";
import CustomButton from "../../../../../Components/CustomButton/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import graph1 from "../../../../../Dexta_assets/graph3.0.png";
import graph2 from "../../../../../Dexta_assets/graph4.0.png";
import graph3 from "../../../../../Dexta_assets/graph3.png";
import DropdownListInterpret from "../../../../../Components/Dropdown/DropdownInterpret";
import closeIcon from "../../../../../Dexta_assets/closeModal.png";

const InterpretResults = ({ helpModal, setHelp }) => {
  const [selectedGraphFilter, setSelectedGraphFilter] = useState(
    "Your candidate pool average"
  );
  const [graphDropdown, setGraphDropdown] = useState(false);
  const user_package_check = useSelector(
    (state) => state.packageDetails.setPackage
  );
  const graphRef = useRef(null);
  const scoresArray = [10, 20, 30, 40, 50, 60, 70, 80];
  const handleGraphFilterClick = (title) => {
    setSelectedGraphFilter(title);
    setGraphDropdown(false);
  };
  return (
    <main className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="bg-black opacity-80 absolute inset-0"
        onClick={() => setHelp(false)}
      ></div>
      <div className="bg-white rounded-lg 2xl:w-2/5 xl:w-3/6 lg:w-3/6 h-5/6 overflow-hidden overflow-y-auto mx-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="bg-white rounded-lg enable-scrollbar2">
          <Scrollbars
            autoHeight
            autoHide
            autoHeightMax="760px"
            style={{ width: "100%", height: "100%" }}
          >
            <img
              src={closeIcon}
              className=" top-3 ml-auto sticky z-20 w-6 h-6 mr-2 cursor-pointer"
              onClick={() => setHelp(false)}
            />
            <div className="px-4 py-1">
              <div className="mt-4 text-left md:text-left">
                <p
                  className="text-xl text-coalColor"
                  style={{ fontFamily: "Archia Bold" }}
                >
                  How to Interpret Test Results
                </p>
              </div>
              <div
                className="text-left text-sm md:text-left mt-5"
                style={{ fontFamily: "Silka" }}
              >
                <p className="text-coalColor mt-0">
                  The test results page provides a detailed overview of a
                  candidate's performance across multiple test modules. Below is
                  a guide to help you understand and interpret the results
                  displayed on the page.
                </p>
              </div>
              <div className="text-left md:text-left mt-5">
                <p
                  className="text-xl text-coalColor"
                  style={{ fontFamily: "Archia Bold" }}
                >
                  Key Elements Explained
                </p>
              </div>

              <ol
                class="list-decimal pl-8 mt-5 text-lg text-coalColor"
                style={{ fontFamily: "Archia Bold" }}
              >
                {/*GRID ONE*/}
                <div className="grid grid-cols-5">
                  <li className="col-span-2">
                    <div className="flex items-center">Overall Scores</div>
                    <ul
                      className="list-disc pl-2 mt-2 text-base"
                      style={{ fontFamily: "Silka" }}
                    >
                      <li>
                        <strong>Total Average Score:</strong> Represents the
                        total performance of the candidate across all modules.
                      </li>
                      <li>
                        <strong>Candidate Pool Average Score:</strong> The
                        average score achieved by all your candidates who took
                        the same test.
                      </li>
                      <li>
                        <strong>Highest Candidate Score:</strong> Indicates the
                        highest score achieved by any candidate in your
                        candidate pool.
                      </li>
                    </ul>
                  </li>
                  <div className="px-2 col-span-3">
                    <div
                      className="pt-14 p w-full px-5 bg-[#DBD9C0] mt-4 mx-auto justify-center rounded flex flex-row sm:gap-14 md:gap-14"
                      style={{ fontFamily: "Silka" }}
                    >
                      <VerticalBar
                        heightOfCell="h-[13rem]"
                        EmptyCellColor="bg-[#FFF8E8]"
                        FilledCellHeight={74}
                        tooltip1Text="Your best"
                        FilledCellColor="bg-[#FFB500]"
                        tooltip2Text="candidate"
                        tooltip4Text="score"
                        tooltip3Text={74 + "%"}
                        paddingTooltip="py-[4px]"
                        marginTopTooltip="-mt-[100px]"
                        widthtooltip="w-[5.8rem]"
                        tooltip1TextSize="text-[10px]"
                        spaceY="-space-y-2"
                      />
                      <VerticalBar
                        heightOfCell="h-[13rem]"
                        EmptyCellColor="bg-[#FFF8E8]"
                        FilledCellColor="bg-[#FF5812]"
                        FilledCellHeight={65}
                        tooltip1Text="John Doe's"
                        tooltip2Text="total"
                        tooltip4Text="score"
                        tooltip3Text={65 + "%"}
                        paddingTooltip="py-[4px]"
                        marginTopTooltip="-mt-[100px]"
                        widthtooltip="w-[5.8rem]"
                        tooltip1TextSize="text-[10px]"
                        spaceY="-space-y-2"
                      />
                      <VerticalBar
                        heightOfCell="h-[13rem]"
                        EmptyCellColor="bg-[#FFF8E8]"
                        FilledCellHeight={68}
                        tooltip1Text="Your candidate"
                        FilledCellColor="bg-[#C0FF06]"
                        tooltip2Text="Pool average"
                        tooltip4Text="score"
                        tooltip3Text={68 + "%"}
                        paddingTooltip="py-[4px]"
                        marginTopTooltip="-mt-[100px]"
                        widthtooltip="w-[5.8rem]"
                        tooltip1TextSize="text-[10px]"
                        spaceY="-space-y-2"
                      />
                    </div>
                  </div>
                </div>
                <hr className="w-full mt-8 bg-gray-900 border-1" />
                {/*GRID TWO*/}
                <div className="grid grid-cols-2 mt-5">
                  <li>
                    <div className="flex items-center mt-5">
                      Module-Specific Results{" "}
                    </div>

                    <ul
                      className="list-disc pl-2 mt-2 text-base"
                      style={{ fontFamily: "Silka" }}
                    >
                      <li>
                        <strong>Module Title:</strong> The title and level of
                        the module (e.g., "Robotics Process Automation L1").
                      </li>
                      <li>
                        <strong>Proficiency Level:</strong> Indicated by tags
                        like "Beginner", "Intermediate", or "Advanced".
                      </li>
                      <li>
                        <strong>Candidate's Score:</strong> Number of correct
                        answers achieved by the candidate against the total
                        number of questions in the test, shown as a percentage.
                      </li>
                      <li>
                        <strong>Average Score: </strong> The average percentage
                        score of all candidates for that particular module.
                        Compare candidate’s score to the average score to see
                        how they performed relative to peers.
                      </li>
                      <li>
                        <strong>Time Spent:</strong> The time spent on each
                        module, given in minutes (e.g. 2 mins/10 mins). This may
                        indicate the difficulty or familiarly with content.
                      </li>
                    </ul>
                  </li>
                  <div
                    className="flex flex-col gap-3 px-10 my-auto"
                    style={{ fontFamily: "Silka" }}
                  >
                    {dummy_sections?.slice(0, 1).map((i, index) => (
                      <div>
                        <div className="bg-[#DBD9C0] h-[18rem] rounded flex flex-col">
                          <p
                            className="px-5 mt-3 h-20 text-base"
                            style={{ fontFamily: "Silka Light" }}
                          >
                            {i?.name}
                          </p>
                          <div className="grid grid-cols-2 px-5 gap-3">
                            <p
                              className={`border rounded-2xl text-[11px] bg-white my-auto text-center ${
                                i?.experience === "Beginner" &&
                                "border-[#0B5B23]"
                              } ${
                                i?.experience === "Intermediate" &&
                                "border-[#FFB500]"
                              } ${
                                i?.experience === "Advanced" &&
                                "border-[#FF5812]"
                              } ${i?.experience === "" && "border-[#C0FF06]"}`}
                              style={{ fontFamily: "Silka" }}
                            >
                              {i?.experience != "" ? i?.experience : "custom"}
                            </p>
                            <div>
                              <p
                                className="text-center text-[11px] py-1 flex"
                                style={{ fontFamily: "Silka" }}
                              >
                                {i?.time}
                              </p>
                            </div>
                          </div>

                          <div className="flex-row gap-20 mt-20 px-10 flex justify-center ">
                            <VerticalBar
                              heightOfCell="h-20"
                              EmptyCellColor="bg-[#DBD9C0]"
                              FilledCellColor="bg-[#FF5812]"
                              FilledCellHeight={50}
                              tooltip1Text="Scored"
                              tooltip3Text={50 + "%"}
                              paddingTooltip="py-2"
                              marginTopTooltip="-mt-[70px]"
                              widthtooltip="w-[6rem]"
                            />
                            <VerticalBar
                              heightOfCell="h-20"
                              EmptyCellColor="bg-[#DBD9C0]"
                              FilledCellColor="bg-[#FFB500]"
                              FilledCellHeight={60}
                              tooltip1Text="Highest score"
                              tooltip3Text={60 + "%"}
                              paddingTooltip="py-2"
                              marginTopTooltip="-mt-[70px]"
                              widthtooltip="w-[6rem]"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <hr className="w-full mt-8 bg-gray-900 border-1" />
                {/*GRID THREE*/}
                <div className="grid grid-cols-2 mt-5">
                  <li>
                    <div className="flex items-center mt-5">
                      Module Specific Comparative Data Points
                    </div>
                    <p
                      className="text-base mt-3"
                      style={{ fontFamily: "Silka" }}
                    >
                      On the top right corner of the results page, there is a
                      dropdown menu that allows you to compare the candidate's
                      score for each module against different benchmarks. These
                      options provide additional context for assessing the
                      candidate's performance at a module specific level:
                    </p>
                    <ul
                      className="list-disc pl-2 text-base mt-5"
                      style={{ fontFamily: "Silka" }}
                    >
                      <li>
                        <strong>Your Best Performing Candidate</strong>
                        <ul class="custom-list pl-5 mt-2">
                          <li>
                            Compares the candidate's score against the highest
                            score achieved by your best performing candidate for
                            a specific module
                          </li>
                          <li>
                            Helps to identify how the candidate score compares
                            to the top performer for each module
                          </li>
                        </ul>
                      </li>
                      <li className="mt-3">
                        <strong> Your Candidate Pool Average</strong>
                        <ul class="custom-list pl-5 mt-2">
                          <li>
                            Compares the candidate's scores to the average score
                            of your candidate pool.
                          </li>
                          <li>
                            Provides insight into how the candidate performs
                            relative to your general pool of candidates.
                          </li>
                        </ul>
                      </li>
                      <li className="mt-3">
                        <strong>Dexta Candidate Pool Average</strong>
                        <ul class="custom-list pl-5 mt-2">
                          <li>
                            Compares the candidate's scores to a broader pool
                            average provided by Dexta.
                          </li>
                          <li>
                            Allows for a comparison against a larger,
                            potentially more diverse set of candidates,
                            providing a wider context for the candidate's
                            performance.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <div>
                    <div className="px-4">
                      <p
                        className="text-coalColor text-lg mt-5 col-span-4"
                        style={{ fontFamily: "Archia Semibold" }}
                      >
                        {/* {data?.candidate?.candidates?.firstName}'s Test results */}
                        Module Scores
                      </p>
                      <div
                        className="my-auto w-full sm:justify-center md:justify-end col-span-8"
                        style={{ fontFamily: "Silka" }}
                      >
                        <div className="relative md:w-full">
                          <CustomButton
                            label={selectedGraphFilter}
                            id="graphID"
                            borderCustom="border border-black graphClass text-xs"
                            paddingY="0.3rem"
                            iconR={RiArrowDropDownLine}
                            iconWidth="w-6 h-6"
                            justifyContent="justify-between"
                            paddingx="px-2"
                            onClickButton={() =>
                              setGraphDropdown(!graphDropdown)
                            }
                          />
                          {graphDropdown && (
                            <div
                              className="absolute z-40 border border-coalColor graphClass right-0 top-full text-xs h-auto overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                              ref={graphRef}
                            >
                              {graph_filters?.map((i) => (
                                <DropdownListInterpret
                                  key={i?.title}
                                  title={i?.title}
                                  textsize="text-xs"
                                  onClick={() =>
                                    handleGraphFilterClick(i?.title)
                                  }
                                  user_package={user_package_check}
                                />
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col gap-3 px-10 mt-5">
                      {dummy_sections?.slice(0, 2).map((i, index) => (
                        <div>
                          <div className="bg-[#DBD9C0] h-[17rem] rounded flex flex-col">
                            <p
                              className="px-5 mt-3 h-20 text-base"
                              style={{ fontFamily: "Silka Light" }}
                            >
                              {i?.name}
                            </p>
                            <div className="grid grid-cols-2 px-5 gap-3">
                              <p
                                className={`border rounded-2xl text-[11px] bg-white my-auto text-center ${
                                  i?.experience === "Beginner" &&
                                  "border-[#0B5B23]"
                                } ${
                                  i?.experience === "Intermediate" &&
                                  "border-[#FFB500]"
                                } ${
                                  i?.experience === "Advanced" &&
                                  "border-[#FF5812]"
                                } ${
                                  i?.experience === "" && "border-[#C0FF06]"
                                }`}
                                style={{ fontFamily: "Silka" }}
                              >
                                {i?.experience != "" ? i?.experience : "custom"}
                              </p>
                              <div>
                                <p
                                  className="text-center text-xs py-1 flex"
                                  style={{ fontFamily: "Silka" }}
                                >
                                  {i?.time}
                                </p>
                              </div>
                            </div>
                            <div className="flex-row gap-20 mt-[80px] px-10 flex justify-center ">
                              <VerticalBar
                                heightOfCell="h-[80px]"
                                EmptyCellColor="bg-[#DBD9C0]"
                                FilledCellColor="bg-[#FF5812]"
                                FilledCellHeight={65}
                                tooltip1Text="Scored"
                                tooltip3Text={65 + "%"}
                                paddingTooltip="py-2"
                                marginTopTooltip="-mt-[70px]"
                                widthtooltip="w-[6rem]"
                              />
                              {i?.experience !== "" && (
                                <>
                                  {selectedGraphFilter ===
                                    "Your best performing candidate" && (
                                    <VerticalBar
                                      filter={selectedGraphFilter}
                                      heightOfCell="h-[80px]"
                                      EmptyCellColor="bg-[#DBD9C0]"
                                      FilledCellColor="bg-[#FFB500]"
                                      FilledCellHeight={74}
                                      tooltip1Text="Highest score"
                                      tooltip3Text={74 + "%"}
                                      paddingTooltip="py-2"
                                      marginTopTooltip="-mt-[70px]"
                                      widthtooltip="w-[6rem]"
                                    />
                                  )}
                                  {selectedGraphFilter ===
                                    "Your candidate pool average" && (
                                    <VerticalBar
                                      filter={selectedGraphFilter}
                                      heightOfCell="h-[80px]"
                                      EmptyCellColor="bg-[#DBD9C0]"
                                      FilledCellColor="bg-[#C0FF06]"
                                      FilledCellHeight={68}
                                      tooltip1Text="Average"
                                      tooltip3Text={68 + "%"}
                                      paddingTooltip="py-2"
                                      marginTopTooltip="-mt-[70px]"
                                      widthtooltip="w-[6rem]"
                                    />
                                  )}
                                  {selectedGraphFilter ===
                                    "Dexta candidate pool average" && (
                                    <VerticalBar
                                      filter={selectedGraphFilter}
                                      heightOfCell="h-[80px]"
                                      EmptyCellColor="bg-[#DBD9C0]"
                                      FilledCellColor="bg-[#252E3A]"
                                      FilledCellHeight={72}
                                      tooltip1Text="Dexta average"
                                      tooltip3Text={72 + "%"}
                                      paddingTooltip="py-2"
                                      marginTopTooltip="-mt-[70px]"
                                      widthtooltip="w-[6rem]"
                                    />
                                  )}
                                  {selectedGraphFilter ===
                                    "Dexta best performing candidate" && (
                                    <VerticalBar
                                      filter={selectedGraphFilter}
                                      heightOfCell="h-[80px]"
                                      EmptyCellColor="bg-[#DBD9C0]"
                                      FilledCellColor="bg-[#A16207]"
                                      FilledCellHeight={85}
                                      tooltip1Text="Dexta Best"
                                      tooltip3Text={85 + "%"}
                                      paddingTooltip="py-2"
                                      marginTopTooltip="-mt-[70px]"
                                      widthtooltip="w-[6rem]"
                                    />
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <hr className="w-full mt-8 bg-gray-900 border-1" />
                {/*GRID FOUR*/}
                <li>
                  <div className="grid grid-cols-2">
                    <div className="flex items-center mt-5">
                      Performance & Completion Time Insights
                    </div>
                    <div></div>
                  </div>
                  <div className="grid grid-cols-2">
                    <div>
                      <p
                        className="text-base mt-3"
                        style={{ fontFamily: "Silka" }}
                      >
                        Provides visual representations of the candidate's
                        performance and completion time in the form of box plots
                        and distribution curves.
                      </p>
                      <ul
                        className="list-disc pl-2 text-base mt-5"
                        style={{ fontFamily: "Silka" }}
                      >
                        <li>
                          <strong>Performance Insights</strong>
                          <ul class="custom-list pl-5 mt-2">
                            <li>
                              <strong>Box Plot:</strong> Shows the distribution
                              of scores within the candidate pool, including
                              minimum, first quartile (Q1), median, third
                              quartile (Q3), and maximum scores.
                            </li>
                            <li>
                              <strong>Distribution Curve:</strong> Overlays a
                              Gaussian distribution to give a sense of where the
                              candidate's score lies within the overall
                              distribution.
                            </li>
                            <li>
                              <strong>Candidate's Score Marker:</strong>{" "}
                              Indicates the candidate's score on the
                              distribution curve and box plot, helping to
                              visualize their performance relative to others.
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                    <img src={graph1} className="my-auto rounded-lg" />
                  </div>
                  <div className="grid grid-cols-2">
                    <ul
                      className="list-disc pl-2 text-base mt-5"
                      style={{ fontFamily: "Silka" }}
                    >
                      <li>
                        <strong>Completion Time Insights</strong>
                        <ul class="custom-list pl-5 mt-2">
                          <li>
                            <strong>Box Plot:</strong> Displays the distribution
                            of completion times, providing insight into how
                            quickly or slowly the candidate completed the test
                            compared to others.
                          </li>
                          <li>
                            <strong>Distribution Curve:</strong> Shows a
                            Gaussian distribution of completion times to
                            contextualize the candidate's performance.
                          </li>
                          <li>
                            <strong>Candidate's Completion Time Marker:</strong>{" "}
                            Highlights the candidate's completion time on the
                            distribution curve and box plot.
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <img src={graph2} className="my-auto rounded-lg" />
                  </div>
                </li>
                <hr className="w-full mt-8 bg-gray-900 border-1" />
                {/*GRID FIVE*/}
                <div className="grid grid-cols-2 mt-8">
                  <li>
                    <div className="flex items-center">
                      Anti-Cheating Monitor
                    </div>
                    <ul
                      className="list-disc pl-2 mt-2 text-base"
                      style={{ fontFamily: "Silka" }}
                    >
                      <li>
                        <strong>Device Used: </strong> Indicates the type of
                        device used for the test (e.g., Desktop).
                      </li>
                      <li>
                        <strong>Location: </strong> The location from which the
                        test was completed.
                      </li>
                      <li>
                        <strong>IP Address Check: </strong> Indicates whether
                        the test was completed once from the IP address.
                      </li>
                      <li>
                        <strong>Webcam Enabled:</strong> States whether the
                        webcam was enabled during the test.
                      </li>
                      <li>
                        <strong>Full-Screen Mode Active:</strong> Indicates if
                        full-screen mode was active throughout the test.
                      </li>
                    </ul>
                  </li>
                  <img src={graph3} className="my-auto" />
                </div>
              </ol>
            </div>
          </Scrollbars>
        </div>
      </div>
    </main>
  );
};

export default InterpretResults;
