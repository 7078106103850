import React, { useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import http from "../../http";
import Loader from "react-loader-spinner";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { attachCard } from "../hooks/attachCard";
import Cardimg from "../../Assets/blackcard.png";
import calendar from "../../Assets/calend.png";
import cvc from "../../Assets/cvc.png";
import { useSelector } from "react-redux";
import { CiCreditCard1 } from "react-icons/ci";
import { setPackageData } from "../../redux/reducers/packageData/packageDataSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
//#region styling of card input fields
const inputStyle = {
  iconColor: "#c4f0ff",
  backgroundColor: "#fff",
  color: "#000",
  fontSize: "16px",
  fontSmoothing: "antialiased",
  ":-webkit-autofill": {
    color: "#000",
  },
  "::placeholder": {
    color: "#6B6B6B",
  },
};
//#endregion

const CheckoutPayment = ({
  paymentDone,
  setPaymentDone,
  loading,
  setIsloading,
  setPaymentOpen,
  refetch,
  shouldUpdate,
  setShouldUpdate,
  subscriptionID,
}) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const queryClient = useQueryClient();
  const userID = localStorage.getItem("CP-USER-ID");
  const user_plan = useSelector((state) => state.planDetails.setPlanDetail);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState({
    cardNumber: "",
    expiry: "",
    cvc: "",
    general: "",
  });

  //#region create subscription
  const createSubscription = async (e) => {
    e.preventDefault();
    try {
      setIsloading(true);

      // Initialize a flag to check for errors
      let hasErrors = false;

      // Handling card number validation
      const cardElement = elements.getElement(CardNumberElement);
      if (cardElement._complete === false) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          cardNumber: "Please enter a valid card number.",
        }));
        hasErrors = true;
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, cardNumber: "" }));
      }

      // Handling expiry date validation
      const expiryElement = elements.getElement(CardExpiryElement);
      if (expiryElement._complete === false) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          expiry: "Invalid expiry date.",
        }));
        hasErrors = true;
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, expiry: "" }));
      }

      // Handling CVC validation
      const cvcElement = elements.getElement(CardCvcElement);
      if (cvcElement._complete === false) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          cvc: "Invalid CVV.",
        }));
        hasErrors = true;
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, cvc: "" }));
      }

      // If there are errors, stop the process and don't call the API
      if (hasErrors) {
        setIsloading(false);
        return;
      }

      // Clear previous general error messages
      setErrors((prevErrors) => ({ ...prevErrors, general: "" }));

      // Create a payment method
      const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: elements?.getElement(CardNumberElement),
        billing_details: {
          name: user_plan?.name,
        },
      });

      // Call the backend to create subscription
      http
        .request({
          method: "post",
          maxBodyLength: Infinity,
          url: "/subscriptions",
          data: JSON.stringify({
            user: parseInt(userID),
            currency: user_plan?.selecteditem,
            package: user_plan?.id,
            interval: user_plan?.selectedType,
            stripePaymentMethodID: paymentMethod?.paymentMethod?.id,
            stripeProductID: user_plan?.productID,
            stripePriceID:
              user_plan?.selectedType === "year"
                ? user_plan?.yearlyID
                : user_plan?.priceID,
            description: "Subscription ................",
          }),
        })
        .then(async (response) => {
          const confirmPayment = await stripe?.confirmCardPayment(
            response.data.clientSecret
          );
          if (confirmPayment?.error) {
            alert(confirmPayment.error.message);
          } else {
            dispatch(setPackageData(response?.data?.code));
            refetch();
            setTimeout(() => {
              setPaymentDone(true);
            }, 1500);
            setTimeout(() => {
              setIsloading(false);
              setPaymentOpen(false);
            }, 2000);
          }
        })
        .catch(function (error) {
          if (error.response) {
            setError(true);
            const errorMessage = error?.response?.data?.message;

            if (Array.isArray(errorMessage)) {
              setTimeout(() => {
                toast.error(errorMessage[0], {
                  toastId: "copy-success",
                });
              }, 500);
            } else if (typeof errorMessage === "string") {
              setTimeout(() => {
                toast.error(errorMessage, {
                  toastId: "copy-success",
                });
              }, 500);
            } else {
              toast.error("An error occurred.", {
                toastId: "copy-success",
              });
            }
            setIsloading(false);
          } else if (error.request) {
            console.log(error.request);
            setError(true);
            setErrorMessage(error.request);
            setIsloading(false);
          } else {
            setError(true);
            setErrorMessage(error.message);
            setIsloading(false);
          }
        });
    } catch (error) {
      setIsloading(false);
    }
  };

  //#endregion

  //#region calling api to store card creds
  //#region create subscription
  const updateSubscription = async (e) => {
    e.preventDefault();
    try {
      setIsloading(true);

      // Error handling flag
      let hasErrors = false;

      // Card validation logic
      const validateCardElement = (element, errorKey, errorMessage) => {
        if (!element?._complete) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [errorKey]: errorMessage,
          }));
          hasErrors = true;
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, [errorKey]: "" }));
        }
      };

      const cardElement = elements.getElement(CardNumberElement);
      const expiryElement = elements.getElement(CardExpiryElement);
      const cvcElement = elements.getElement(CardCvcElement);

      validateCardElement(
        cardElement,
        "cardNumber",
        "Please enter a valid card number."
      );
      validateCardElement(expiryElement, "expiry", "Invalid expiry date.");
      validateCardElement(cvcElement, "cvc", "Invalid CVV.");

      // Stop execution if there are validation errors
      if (hasErrors) {
        setIsloading(false);
        return;
      }

      // Clear general errors
      setErrors((prevErrors) => ({ ...prevErrors, general: "" }));

      // Create payment method
      const paymentMethod = await stripe?.createPaymentMethod({
        type: "card",
        card: elements?.getElement(CardNumberElement),
        billing_details: {
          name: user_plan?.name,
        },
      });

      const methodID = paymentMethod?.paymentMethod?.id;

      if (!methodID) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          general: "Payment method creation failed.",
        }));
        setIsloading(false);
        return;
      }

      // Attach card using mutateAsync
      const attachCardData = { id: userID, methodID };

      try {
        await mutateAsync(attachCardData);
      } catch (error) {
        setIsloading(false);
        const errorMessage =
          error?.response?.data?.message || "Error attaching payment method.";
        toast.error(errorMessage, { toastId: "attach-error" });
        return;
      }

      // Call backend to create subscription
      const subscriptionData = {
        user: parseInt(userID),
        currency: user_plan?.selecteditem,
        package: user_plan?.id,
        interval: user_plan?.selectedType,
        stripePaymentMethodID: methodID,
        stripeProductID: user_plan?.productID,
        subscription: subscriptionID,
        stripePriceID:
          user_plan?.selectedType === "year"
            ? user_plan?.yearlyID
            : user_plan?.priceID,
        description: "Subscription ................",
      };

      try {
        const response = await http.request({
          method: "post",
          maxBodyLength: Infinity,
          url: "/subscriptions/upgrade",
          data: JSON.stringify(subscriptionData),
        });

        dispatch(setPackageData(response?.data?.code));
        refetch();

        setTimeout(() => {
          setPaymentDone(true);
          setIsloading(false);
          setPaymentOpen(false);
        }, 2000);
      } catch (error) {
        setIsloading(false);
        const errorMessage =
          error?.response?.data?.message || "Subscription upgrade failed.";
        toast.error(errorMessage, { toastId: "subscription-error" });
      }
    } catch (error) {
      setIsloading(false);
      toast.error("An unexpected error occurred.", {
        toastId: "unexpected-error",
      });
    }
  };
  //#endregion

  //#region useMutation with mutateAsync
  const { mutateAsync } = useMutation(attachCard, {
    onSuccess: () => {
      queryClient.invalidateQueries("/subscriptions/attachPaymentMethod");
    },
  });
  //#endregion

  //#endregion

  return (
    <>
      <div className="flex w-1/3 bg-[#F0F0F0] shadow mt-4 mx-auto flex-col p-8">
        <div className="p-4 border border-[#ddd] rounded-lg">
          <form style={{ fontFamily: "Silka" }}>
            <div>
              <p className="text-[0.93rem] text-[#30313d]">Card Number</p>
              <div className="transform mt-1 shadow bg-white focus:shadow transition duration-500 focus:scale-105 rounded w-full py-3 px-3 text-gray-700 leading-tight">
                <div style={{ position: "relative" }}>
                  <CardNumberElement
                    options={{
                      style: {
                        base: inputStyle,
                      },
                    }}
                  />
                  <img
                    src={Cardimg}
                    alt="Card Image"
                    className="w-6 h-6"
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                    }}
                  />
                </div>
              </div>
              {errors.cardNumber && (
                <div className="ml-1">
                  <p className="text-rose-500 sm:text-xs md:text-sm fade-in-image">
                    {errors.cardNumber}
                  </p>
                </div>
              )}
              <div className="w-full mt-3 flex flex-row gap-3">
                <div className="w-1/2">
                  <p className="text-[0.93rem] text-[#30313d]">Expiry</p>
                  <div className="transform mt-1 shadow bg-white focus:shadow transition duration-500 focus:scale-105 rounded w-full py-3 px-3 text-gray-700 leading-tight">
                    <div style={{ position: "relative" }}>
                      <CardExpiryElement
                        options={{
                          style: {
                            base: inputStyle,
                          },
                        }}
                      />
                      <img
                        src={calendar}
                        alt="Card Image"
                        className="w-6 h-6"
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                        }}
                      />
                    </div>
                  </div>
                  {errors.expiry && (
                    <div className="ml-1">
                      <p className="text-rose-500 sm:text-xs md:text-sm fade-in-image">
                        {errors.expiry}
                      </p>
                    </div>
                  )}
                </div>
                <div className="w-1/2">
                  <p className="text-[0.93rem] text-[#30313d]">CVC</p>
                  <div className="transform mt-1 shadow bg-white focus:shadow transition duration-500 focus:scale-105 rounded w-full py-3 px-3 text-gray-700 leading-tight">
                    <div style={{ position: "relative" }}>
                      <CardCvcElement
                        options={{
                          style: {
                            base: inputStyle,
                          },
                        }}
                      />
                      <img
                        src={cvc}
                        alt="Card Image"
                        className="w-6 h-6"
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                        }}
                      />
                    </div>
                  </div>
                  {errors.cvc && (
                    <div className="ml-1">
                      <p className="text-rose-500 sm:text-xs md:text-sm fade-in-image">
                        {errors.cvc}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {errors.general && (
              <div className="ml-1">
                <p className="text-rose-500 sm:text-xs md:text-sm fade-in-image">
                  {errors.general}
                </p>
              </div>
            )}
          </form>
          <p
            className="text-xs mt-5 text-[#6d6e78]"
            style={{ fontFamily: "Silka" }}
          >
            By providing your card information, you allow TestGorilla BV to
            charge your card for future payments in accordance with their terms.
          </p>
        </div>
        <div className="flex flex-row mx-auto gap-1 mb-4 mt-3">
          <CiCreditCard1 className="w-6 h-8" />
          <p className="my-auto" style={{ fontFamily: "Silka" }}>
            Secure Checkout
          </p>
        </div>
      </div>

      <hr className=" w-1/3 mt-10 bg-black border-1 mx-auto" />
      <div className="flex w-1/3 bg-[#F0F0F0] shadow mt-4 mx-auto p-4 flex-col mb-20">
        <div className="flex flex-col">
          <h1
            className="font-bold text-xl"
            style={{ fontFamily: "Archia Bold" }}
          >
            {user_plan?.name}{" "}
            {user_plan?.selectedType === "month" ? "montly" : "annual"} plan
          </h1>
          <p style={{ fontFamily: "Silka" }}>
            {user_plan?.selectedType === "month" ? "Monthly" : "Annual"} payment
          </p>
        </div>
        <div className="flex justify-between mt-8">
          <div className="flex flex-col">
            <h1
              style={{ fontFamily: "Archia Bold" }}
              className="font-bold text-2xl"
            >
              {user_plan?.selectedSign}
              {Intl.NumberFormat("en-US", {
                style: "decimal",
                minimumFractionDigits: 0,
              }).format(user_plan?.selectedAmount)}{" "}
              {user_plan?.selectedType === "month" ? (
                <span
                  style={{ fontFamily: "Archia Semibold" }}
                  className="text-[14px]"
                >
                  per month
                </span>
              ) : (
                <span className="text-[14px]">per year</span>
              )}
            </h1>
          </div>
          <button
            className="inline-flex items-center w-1/3 2xl:w-1/4 py-2 border border-coalColor justify-center px-4 bg-primaryGreen hover:bg-coalColor hover:text-white text-coalColor text-sm font-medium rounded-md"
            type="submit"
            style={{ fontFamily: "Silka" }}
            onClick={(e) => {
              if (shouldUpdate) {
                updateSubscription(e);
              } else {
                createSubscription(e);
              }
            }}
          >
            {loading ? (
              <span className="flex items-center justify-center">
                <Loader type="Oval" color="black" height={15} width={15} />
                <span className="ml-2">Subscribing</span>
              </span>
            ) : (
              "Subscribe"
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default CheckoutPayment;
