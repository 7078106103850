import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  currentTab: "My profile",
};

export const SettingsTabSlice = createSlice({
  name: "settingsTab",
  initialState,
  reducers: {
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
  },
});

export const { setCurrentTab } = SettingsTabSlice.actions;
export default SettingsTabSlice.reducer;
