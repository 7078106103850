import React, { useEffect } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchQuestions } from "./fetchQuestions";
import { useParams, useNavigate } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import styles from "./styling.module.css";
import Logo from "../../Dexta_assets/logodexta2.png";
import { GoArrowRight } from "react-icons/go";
import eye from "../../Dexta_assets/magnifier.png";
import closeIcon from "../../Dexta_assets/closeModal.png";

const Preview_admin = () => {
  const previewData = useSelector((state) => state.preview.setPreview);
  const [selectedOption, setSelectedOption] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const { qid } = useParams();
  const cpToken = localStorage.getItem("admin-token");
  const cpID = localStorage.getItem("admin-id");
  const navigate = useNavigate();

  const buttonStyle = {
    background: isHovered ? "#252E3A" : "#252E3A",
    transition: "background-color 0.1s, transform 0.1s",
    transform: isHovered ? "scale(1.02)" : "scale(1)",
    fontFamily: "Silka",
  };

  //#region Handle radios
  const handleRadios = (id) => {
    setSelectedOption((prevSelected) =>
      prevSelected.includes(id) ? [] : [id]
    );
  };
  //#endregion

  //#region Handle checboxes
  const handleCheckbox = (id) => {
    if (selectedOption.includes(id)) {
      setSelectedOption(selectedOption.filter((item) => item !== id));
    } else {
      setSelectedOption([...selectedOption, id]);
    }
  };
  //#endregion

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  const closeTab = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };

  // useEffect(() => {
  //   if (!cpToken && !cpID) {
  //     window.location = process.env.REACT_APP_STATIC_SITE + "/as-admin/login";
  //   }
  // }, []);

  //#region Fetching Questions
  const { data, isLoading, error } = useQuery({
    queryKey: ["questions", qid],
    queryFn: () => fetchQuestions(qid),
    retry: false,
  });
  //#endregion

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);

  const handleImageClick = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setIsModalOpen(true);
  };

  return (
    <>
      <div className="flex relative cursor-pointer">
        <nav className="w-full top-0 left-0 py-2 bg-white">
          <div className="w-3/4 px-2 items-center py-2 text-xl text-white mx-auto">
            <div className="grid grid-cols-3 container mx-auto">
              <img
                src={Logo}
                className="my-auto object-contain"
                style={{ height: "70px" }}
              />
              <div className=" my-auto w-full">
                <div className="flex flex-col gap-2"></div>
              </div>
              <div className="my-auto ml-auto flex">
                <div className="">
                  <button
                    className="inline-flex items-center w-full justify-center px-8 py-3 my-auto bg-coalColor hover:bg-primaryGreen text-white hover:text-coalColor border border-coalColor text-sm font-medium rounded-md"
                    style={{ fontFamily: "Silka" }}
                    onClick={() => {
                      closeTab();
                    }}
                  >
                    Finish
                    <AiOutlineArrowRight className="w-5 h-5 ml-2 icon-image" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>

      {isModalOpen && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
          onClick={() => setIsModalOpen(false)}
        >
          <div className="relative bg-white p-5 rounded-md">
            <img
              src={closeIcon}
              className="absolute top-3 right-5 z-20 w-6 h-6 cursor-pointer"
              onClick={() => setIsModalOpen(false)}
            />
            <img
              src={selectedImageUrl}
              alt="Selected"
              className="p-5 object-contain"
              style={{
                height: "450px",
                width: "850px",
              }}
            />
          </div>
        </div>
      )}
      <div className="bg-bodyColor">
        <div className="mx-auto container">
          <div className="flex justify-center h-screen mb-[190px]">
            <div className="mt-[50px] w-5/6 rounded-lg ">
              {isLoading ? (
                <div class="loader-container-1">
                  <div class="loader-1"></div>
                </div>
              ) : (
                <div className="rounded-lg  bg-white">
                  <div className="p-8">
                    <React.Fragment>
                      <div className="grid grid-cols-2 gap-5">
                        <div className="pt-3.5 pl-5 ">
                          <div className="pr-8">
                            <h1
                              className="text-black text-xl font-bold"
                              style={{ fontFamily: "Archia Bold" }}
                            >
                              QUESTION
                            </h1>
                            <div
                              className="mt-5"
                              style={{ fontFamily: "Silka" }}
                            >
                              <div className={styles["html-content"]}>
                                {ReactHtmlParser(data?.description)}
                              </div>
                            </div>
                            {data?.image != null &&
                              data?.image != " " &&
                              data?.image != "" && (
                                <div className="mt-3">
                                  <img
                                    src={data?.image}
                                    className="rounded-lg object-cover cursor-pointer h-auto mt-5"
                                    style={{
                                      width: "100%",
                                    }}
                                    onClick={() =>
                                      handleImageClick(data?.image)
                                    }
                                  />
                                </div>
                              )}
                            {data?.figImage != null &&
                              data?.figImage != " " &&
                              data?.figImage != "" && (
                                <div className="">
                                  <img
                                    src={data?.figImage}
                                    className="rounded-lg object-cover cursor-pointer h-auto mt-5"
                                    style={{
                                      width: "100%",
                                    }}
                                    onClick={() =>
                                      handleImageClick(data?.figImage)
                                    }
                                  />
                                </div>
                              )}
                          </div>
                        </div>
                        {(() => {
                          let options_check;
                          if (
                            data?.options?.every(
                              (option) => option.imageUrl === null
                            )
                          ) {
                            options_check = "null";
                          } else if (
                            data?.options?.every(
                              (option) =>
                                option.imageUrl !== null &&
                                option?.title !== null
                            )
                          ) {
                            options_check = "all";
                          } else if (
                            data?.options?.every(
                              (option) =>
                                option.imageUrl !== null &&
                                option?.title === null
                            )
                          ) {
                            options_check = "all images";
                          } else {
                            options_check = "some_image";
                          }

                          return data?.type === "Single" ? (
                            <div className="pt-5 pl-2">
                              <div
                                className="px-10 text-black text-xl font-bold rounded-lg"
                                style={{ fontFamily: "Archia Bold" }}
                              >
                                SELECT ONLY ONE
                                {data?.options
                                  ?.sort((a, b) =>
                                    a?.optionPosition > b?.optionPosition
                                      ? 1
                                      : -1
                                  )
                                  // Group options into pairs for two per row
                                  .reduce((acc, curr, index, array) => {
                                    if (index % 2 === 0)
                                      acc.push(array.slice(index, index + 2));
                                    return acc;
                                  }, [])
                                  .map((pair, rowIndex) => (
                                    <div
                                      key={rowIndex}
                                      className={`grid ${
                                        options_check === "all images" &&
                                        "grid-cols-2"
                                      }  gap-4 mt-5`}
                                    >
                                      {pair.map((j) => (
                                        <>
                                          {options_check === "all images" ? (
                                            <div
                                              key={j.id}
                                              onClick={() => handleRadios(j.id)}
                                              className={`cursor-pointer hover:animate-[jiggle_1s_ease-in-out_infinite]`}
                                            >
                                              <div className="relative flex items-center justify-center">
                                                {/* Container for image and zoom button */}
                                                <div className="relative group">
                                                  <img
                                                    src={j?.imageUrl}
                                                    className="w-[270px] h-[200px] object-cover rounded-lg transition-opacity duration-300"
                                                    style={{
                                                      borderColor:
                                                        selectedOption.includes(
                                                          j.id
                                                        )
                                                          ? "#C0FF06"
                                                          : "white",
                                                      borderStyle: "solid",
                                                      borderWidth: "3px",
                                                    }}
                                                  />

                                                  <div className="absolute top-2 left-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                    <button
                                                      className="bg-white text-black px-3 py-2 rounded-lg shadow-md"
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleImageClick(
                                                          j?.imageUrl
                                                        );
                                                      }}
                                                    >
                                                      <img
                                                        src={eye}
                                                        className="w-5 h-5 object-contain"
                                                      />
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <div
                                              key={j.id}
                                              onClick={() => handleRadios(j.id)}
                                              className={`p-3 2xl:p-5 mt-5 cursor-pointer ${
                                                selectedOption.includes(j.id)
                                                  ? "bg-black"
                                                  : null
                                              } hover:animate-[jiggle_1s_ease-in-out_infinite] rounded-2xl border border-[#B6B6B6]`}
                                              style={{
                                                background:
                                                  selectedOption.includes(j.id)
                                                    ? "#C0FF06"
                                                    : "white",
                                              }}
                                            >
                                              <div className="flex justify-between">
                                                <div className="flex gap-10">
                                                  <div className="inline-flex items-center">
                                                    <label
                                                      className="relative flex cursor-pointer items-center rounded-full p-3"
                                                      htmlFor={`radio_${j.id}`}
                                                      data-ripple-dark="true"
                                                    >
                                                      <input
                                                        id={`radio_${j.id}`}
                                                        name="type"
                                                        type="radio"
                                                        className="peer relative 2xl:h-7 2xl:w-7 w-5 h-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-coalColor transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:opacity-0 before:transition-opacity border-black checked:border-coalColor checked:before:bg-black hover:before:opacity-10"
                                                        onChange={() =>
                                                          handleRadios(j.id)
                                                        }
                                                        checked={selectedOption.includes(
                                                          j.id
                                                        )}
                                                      />
                                                      <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-coalColor opacity-0 transition-opacity peer-checked:opacity-100">
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          className="2xl:h-5 2xl:w-5 w-3 h-3"
                                                          viewBox="0 0 16 16"
                                                          fill="currentColor"
                                                        >
                                                          <circle
                                                            data-name="ellipse"
                                                            cx="8"
                                                            cy="8"
                                                            r="8"
                                                          ></circle>
                                                        </svg>
                                                      </div>
                                                    </label>
                                                    <label
                                                      className={`mt-px cursor-pointer select-none text-sm font-medium`}
                                                      htmlFor={`radio_${j.id}`}
                                                      style={{
                                                        fontFamily: "Silka",
                                                        color:
                                                          selectedOption.includes(
                                                            j.id
                                                          )
                                                            ? "black"
                                                            : "#252e3a",
                                                      }}
                                                    >
                                                      {j.title}
                                                    </label>
                                                  </div>
                                                </div>
                                                {(options_check === "all" ||
                                                  options_check ===
                                                    "some_image") && (
                                                  <div className="my-auto">
                                                    {j?.imageUrl ? (
                                                      <div className="relative flex items-center justify-center">
                                                        {/* Container for image and zoom button */}
                                                        <div className="relative group">
                                                          <img
                                                            src={j?.imageUrl}
                                                            className="my-auto w-20 h-20 object-cover"
                                                          />

                                                          <div className="absolute top-1 left-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                            <button
                                                              className="bg-white text-black px-2 py-2 rounded-md shadow-md"
                                                              onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleImageClick(
                                                                  j?.imageUrl
                                                                );
                                                              }}
                                                            >
                                                              <img
                                                                src={eye}
                                                                className="w-3 h-3 object-contain"
                                                              />
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      <div className="w-20 h-20"></div>
                                                    )}
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      ))}
                                    </div>
                                  ))}
                              </div>
                              <div className="flex justify-center my-auto px-10 mt-8">
                                <button
                                  className={`inline-flex items-center w-full justify-center px-8 py-4 my-auto text-white text-sm font-medium rounded-md`}
                                  style={buttonStyle}
                                  onMouseEnter={handleHover}
                                  onMouseLeave={handleLeave}
                                  onClick={closeTab}
                                >
                                  Finish
                                  <AiOutlineArrowRight className="w-5 h-5 ml-2 icon-image" />
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div className="pt-5 pl-2">
                              <div className="px-10 text-black text-xl font-bold rounded-lg">
                                SELECT ALL THAT APPLY
                                {data?.options
                                  ?.sort((a, b) =>
                                    a?.optionPosition > b?.optionPosition
                                      ? 1
                                      : -1
                                  )
                                  .reduce((acc, curr, index, array) => {
                                    if (index % 2 === 0)
                                      acc.push(array.slice(index, index + 2));
                                    return acc;
                                  }, [])
                                  .map((pair, rowIndex) => (
                                    <div
                                      key={rowIndex}
                                      className={`grid ${
                                        options_check === "all images" &&
                                        "grid-cols-2"
                                      }  gap-4 mt-5`}
                                    >
                                      {pair.map((j) => (
                                        <>
                                          {options_check === "all images" ? (
                                            <div
                                              key={j.id}
                                              onClick={() =>
                                                handleCheckbox(j.id)
                                              }
                                              className={`cursor-pointer hover:animate-[jiggle_1s_ease-in-out_infinite]`}
                                            >
                                              <div className="relative flex items-center justify-center">
                                                {/* Container for image and zoom button */}
                                                <div className="relative group">
                                                  <img
                                                    src={j?.imageUrl}
                                                    className="w-[270px] h-[200px] object-cover rounded-lg transition-opacity duration-300"
                                                    style={{
                                                      borderColor:
                                                        selectedOption.includes(
                                                          j.id
                                                        )
                                                          ? "#C0FF06"
                                                          : "white",
                                                      borderStyle: "solid",
                                                      borderWidth: "3px",
                                                    }}
                                                  />

                                                  <div className="absolute top-2 left-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                    <button
                                                      className="bg-white text-black px-3 py-2 rounded-lg shadow-md"
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleImageClick(
                                                          j?.imageUrl
                                                        );
                                                      }}
                                                    >
                                                      <img
                                                        src={eye}
                                                        className="w-5 h-5 object-contain"
                                                      />
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <div
                                              className={`p-3 2xl:p-5 mt-3 rounded-2xl border border-[#B6B6B6] cursor-pointer hover:animate-[jiggle_1s_ease-in-out_infinite]`}
                                              key={j.id}
                                              style={{
                                                background:
                                                  selectedOption.includes(j.id)
                                                    ? "#C0FF06"
                                                    : "white",
                                              }}
                                              onClick={() =>
                                                handleCheckbox(j?.id)
                                              }
                                            >
                                              <div className="flex justify-between">
                                                <div className="inline-flex items-center">
                                                  <label
                                                    className="relative flex cursor-pointer items-center rounded-full p-3"
                                                    htmlFor={`radio_${j.id}`}
                                                    data-ripple-dark="true"
                                                  >
                                                    <input
                                                      id="ripple-on"
                                                      type="checkbox"
                                                      className={`peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 border-gray-600  before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-coalColor  hover:before:opacity-10`}
                                                      onClick={() =>
                                                        handleCheckbox(j?.id)
                                                      }
                                                      checked={selectedOption.includes(
                                                        j.id
                                                      )}
                                                      onChange={() =>
                                                        handleCheckbox(j?.id)
                                                      }
                                                    />
                                                    <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-coalColor opacity-0 transition-opacity peer-checked:opacity-100">
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-3.5 w-3.5"
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                        stroke="currentColor"
                                                        strokeWidth="1"
                                                      >
                                                        <path
                                                          fillRule="evenodd"
                                                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                          clipRule="evenodd"
                                                        ></path>
                                                      </svg>
                                                    </div>
                                                  </label>
                                                  <label
                                                    className={`mt-px cursor-pointer select-none text-sm font-medium ${
                                                      selectedOption?.includes(
                                                        j?.id
                                                      )
                                                        ? "text-[#FFFFFF]"
                                                        : "text-coalColor"
                                                    }`}
                                                    htmlFor="html"
                                                    style={{
                                                      fontFamily: "Silka",
                                                      color:
                                                        selectedOption.includes(
                                                          j.id
                                                        )
                                                          ? "#black"
                                                          : "#252e3a",
                                                    }}
                                                  >
                                                    {j.title}
                                                  </label>
                                                </div>
                                                {(options_check === "all" ||
                                                  options_check ===
                                                    "some_image") && (
                                                  <div className="my-auto">
                                                    {j?.imageUrl ? (
                                                      <div className="relative flex items-center justify-center">
                                                        {/* Container for image and zoom button */}
                                                        <div className="relative group">
                                                          <img
                                                            src={j?.imageUrl}
                                                            className="my-auto w-20 h-20 object-cover"
                                                          />

                                                          <div className="absolute top-1 left-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                            <button
                                                              className="bg-white text-black px-2 py-2 rounded-md shadow-md"
                                                              onClick={() =>
                                                                handleImageClick(
                                                                  j?.imageUrl
                                                                )
                                                              }
                                                            >
                                                              <img
                                                                src={eye}
                                                                className="w-3 h-3 object-contain"
                                                              />
                                                            </button>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      <div className="w-20 h-20"></div>
                                                    )}
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      ))}
                                    </div>
                                  ))}
                              </div>
                              <div className="flex justify-center my-auto px-10 mt-8">
                                <button
                                  className={`inline-flex items-center w-full justify-center px-8 py-4 my-auto text-white text-sm font-medium rounded-md`}
                                  style={buttonStyle}
                                  onMouseEnter={handleHover}
                                  onMouseLeave={handleLeave}
                                  onClick={closeTab}
                                >
                                  Finish
                                  <AiOutlineArrowRight className="w-5 h-5 ml-2 icon-image" />
                                </button>
                              </div>
                            </div>
                          );
                        })()}
                      </div>
                    </React.Fragment>
                  </div>
                </div>
              )}
              {/* <h1 className='text-xl font-bold py-5'>Mobile Home Park Investment Analysis and Strategy</h1> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Preview_admin;
