import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import "react-toastify/dist/ReactToastify.css";
import { useRef } from "react";
import "../../../../Components/Loading/Loading.css";
import { getAssessmentDetails } from "./hooks/getAssessmentDetails";
import queryString from "query-string";
import "../../../../Components/Loading/Loading.css";
import moment from "moment";
import { updateAssessmentStatus } from "./hooks/updateStatus";
import { ToastContainer, toast, Zoom } from "react-toastify";
import http from "../../../../http";
import { deleteUser } from "./hooks/deleteUser";
import { styled } from "@mui/material/styles";
import { getHiringstatuses } from "./hooks/getHiringStatusList";
import { updateHiringStatus } from "./hooks/updateHiringStatus";
import "./invite.css";
import { getCandidateLogs } from "./hooks/getCandidateLogs";
import Scrollbars from "react-custom-scrollbars";
import { Slider } from "@mui/material";
import VerticalBar from "../../../../Components/VerticalBar/VerticalBar";
import DeleteIcon from "../../../../Dexta_assets/deleteIcon.png";
import DeleteModal from "../../../../Components/ConfirmationModals/DeleteModal";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { setCandidateToFalse } from "../../../../redux/reducers/CandidateBack/CandidateSlice";
import { useDispatch, useSelector } from "react-redux";
import InterpretResults from "./Modals/InterpretResults";
import { getPerformanceData } from "./hooks/getPerformanceData";
import { getCompletionData } from "./hooks/getCompletionData";
import RejectCandidate from "./Modals/RejectCandidate";
import ConfirmModal from "../../../../Components/ConfirmationModals/ConfirmModal";
import confirm from "../../../../Dexta_assets/confirm.png";
import Lock from "../../../../Dexta_assets/sample_lock.png";
import CustomButton from "../../../../Components/CustomButton/CustomButton";
import { RiArrowDropDownLine } from "react-icons/ri";
import { dummy_sections, graph_filters } from "./data";
import DropdownList from "../../../../Components/Dropdown/Dropdown";
import useOutsideClick from "../../../../Components/OutsideClick/OutsideClick";
import BoxPlotChart from "../../../../Components/chart/demo";
import BoxPlotChart2 from "../../../../Components/chart/demo3";
import { PiDotsThreeCircle } from "react-icons/pi";
import useWindowSize from "../../../../Helpers/useWindowSize";
import { setGraphData } from "../../../../redux/reducers/GraphData/GraphDataSlice";
import PassCandidate from "./Modals/PassCandidate";
import Premium from "../../../../Components/Modals/Premium";
import HiringModal from "./Modals/HiringModal";
import { IoIosMail } from "react-icons/io";

//#region styling of dropdowns
const IOSSlider = styled(Slider)(({ theme }) => ({
  color: "#D3D5D8", // Change the primary color to gray
  height: 5,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    display: "none", // Remove the thumb (circle)
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: -6,
    backgroundColor: "unset",
    color: "#000", // Change the color to black
    "&::before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: "#000", // Change the color to black
    },
  },
  "& .MuiSlider-track": {
    border: "none",
    height: 5,
    backgroundColor: "#FF5812", // Change the filled track color to orange
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    boxShadow: "inset 0px 0px 4px -2px #000",
    backgroundColor: "white", // Change the remaining track color to white
  },
}));

//#endregion

const Details_Assessment = () => {
  //#region useStates
  const queryClient = useQueryClient();
  const { id } = useParams();
  const navigate = useNavigate();
  const userID = parseInt(localStorage.getItem("CP-USER-ID"));
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const [status, setStatus] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errors, setError] = useState(false);
  const [helpModal, setHelp] = useState(false);
  const hiringRef = useRef(null);
  const [editEnabled, setEditEnabled] = useState(false);
  const [dropsettings, setdropsettings] = useState(false);
  const [downloadDropdown, setDownloadDropdown] = useState(false);
  const threedotsRef = useRef(null);
  const downloadRef = useRef(null);
  const dispatch = useDispatch();
  const backCheck = useSelector((state) => state.candidateBack.setcandidate);
  const [ImageLoading, setImageLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [rejectOpen, setRejectOpen] = useState(false);
  const [graphDropdown, setGraphDropdown] = useState(false);
  const graphRef = useRef(null);
  const [emailContent, setEmailContent] = useState("");
  const [selectedGraphFilter, setSelectedGraphFilter] = useState(
    "Your candidate pool average"
  );
  const user_role = useSelector(
    (state) => state.accountTypeDetails.accountType
  );
  const size = useWindowSize();
  const isMobile = size.width <= 640;
  const [passOpen, setPassOpen] = useState(false);
  const [premiumOpen, setPremiumOpen] = useState(false);
  const [hiringOpen, setHiringOpen] = useState(false);
  const [hiringLabel, sethiringLabel] = useState("");
  const [hiringStatus, sethiringStatus] = useState("");
  const user_package_check = useSelector(
    (state) => state.packageDetails.setPackage
  );
  const [scoresArray, setScoresArray] = useState([]);
  const [timesArray, setTimeArray] = useState([]);
  const scoresArray_dummy = [10, 20, 30, 40, 50, 60, 70, 80];
  const timesArray_dummy = [10, 20, 30, 40, 50, 60, 70, 80];
  const [typeHiring, settypeHiring] = useState("");
  const [checkboxText, setcheckboxText] = useState("");
  //#endregion

  //#region Fetch details of User assessment
  const { data, error, isLoading, refetch } = useQuery(
    ["/assessment/candidate/result", id, parsed.user_ID],
    () => getAssessmentDetails(id, parsed.user_ID),
    {
      onSuccess: (data) => {
        if (data?.notes != null) {
          setStatus(data?.notes);
        }
      },
    }
  );

  console.log(data?.Webcam, "web cam status");
  //#endregion

  //#region update Status
  const { mutate, isLoading: mutateLoading } = useMutation(
    updateAssessmentStatus,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries("/candidate/update");
        setEditEnabled(false);
      },
      onError: () => {},
    }
  );
  //#endregion

  //#region send email to candidate
  const resolveAfter3Sec = new Promise((resolve) => setTimeout(resolve, 5000));
  const send_email = async () => {
    const response = await http.get(
      `/assessment/candidate/result/send-email/assessment/${id}/user/${parsed.user_ID}`
    );
    if (response.data === "Email Sent!") {
      setConfirmModal(false);
      toast.promise(resolveAfter3Sec, {
        pending: "Send Email",
        success: "Email has been sent to the candidate.",
        error: "error",
      });
    }
    return response;
  };
  //#endregion

  //#region Deleting Candidate
  const { mutate: deleteUserMutate, isLoading: deleteUserLoading } =
    useMutation(deleteUser, {
      onSuccess: () => {
        queryClient.invalidateQueries("assessment");
        setDeleteModal(false);
        setTimeout(() => {
          navigate(`/invite-candidates/${id}`);
        }, 1000);
      },
      onError: (error) => {
        setError(true);
        setErrorMessage(error.response.data.message[0]);
      },
    });

  const handleDeleteUser = () => {
    let data = {
      ID: {
        assessmentID: id,
      },
      ID2: {
        candidateID: parsed.user_ID,
      },
    };

    try {
      deleteUserMutate(data);
      toast.success("User has been deleted", {
        toastId: "copy-success",
      });
    } catch (err) {
      console.log(err.message);
    }
  };
  //#endregion

  //#region Fetching hiring statuses list
  const { data: hiringData, isLoading: HiringLoading } = useQuery(
    ["hiring"],
    () => getHiringstatuses()
  );

  //#endregion

  //#region updating hiring status
  const { mutate: hiringMutate, isLoading: hiringLoading } = useMutation(
    updateHiringStatus,
    {
      onSuccess: async (response) => {
        queryClient.invalidateQueries("/assessment/candidate");
        queryClient.invalidateQueries(["assessment"]);
        await queryClient.refetchQueries(["assessment"]);
        refetch();
      },
      onError: () => {},
    }
  );

  const handlehiringChange = (i) => {
    if (i === "INVITED FOR INTERVIEW") {
      sethiringStatus("Invite candidate for interview");
      sethiringLabel("Are you inviting the candidate for an interview?");
      setcheckboxText("Tick here to send an interview invite through Dexta");
      setHiringOpen(true);
      settypeHiring(i);
    } else if (i === "OFFER SENT") {
      sethiringStatus("Send offer to candidate");
      sethiringLabel("Are you planning on hiring candidate?");
      setcheckboxText(
        "Would you like to notify the candidate of the offer through Dexta?"
      );
      setHiringOpen(true);
      settypeHiring(i);
    } else if (i === "REJECTED") {
      sethiringStatus("Reject candidate");
      sethiringLabel("Are you rejecting the candidate?");
      setcheckboxText(
        "Would you like to notify the candidate of the rejection through Dexta?"
      );
      setHiringOpen(true);
      settypeHiring(i);
    } else if (i === "REMINDER TO RESPOND TO OFFER") {
      sethiringStatus("Send reminder to candidate");
      sethiringLabel(
        "Do you want to send reminder to this candidate to respond to offer?"
      );
      setHiringOpen(true);
      settypeHiring(i);
    } else if (i === "HIRED") {
      sethiringStatus("Congratulate candidate on being hired");
      sethiringLabel("Has the candidate accepted your offer?");
      setcheckboxText(
        "Would you like to congratulate candidate through Dexta?"
      );
      setHiringOpen(true);
      settypeHiring(i);
    } else if (i === "PASSED") {
      sethiringStatus("Pass candidate");
      sethiringLabel("Do you want to pass this candidate?");
      setcheckboxText("Send a pass email to this candidate as well");
      setHiringOpen(true);
      settypeHiring(i);
    } else if (i === "POSITION WITHDRAWN") {
      sethiringStatus("Notify candidate of position withdrawal");
      sethiringLabel("Are you no longer hiring for this position?");
      setcheckboxText("Would you like to notify candidate through Dexta?");
      setHiringOpen(true);
      settypeHiring(i);
    } else {
      let data = {
        IDS: {
          userID: parsed.user_ID,
          Assessment: id,
        },
        postData: JSON.stringify({
          status: i,
        }),
      };
      try {
        hiringMutate(data);
        toast.success("Hiring stage changed for candidate.", {
          toastId: "copy-success",
          style: { width: "350px" },
        });
      } catch (err) {
        //react-query will handle error
      }
    }
  };
  //#endregion

  //#region fetching logs
  const { data: logsData, isLoading: logsLoading } = useQuery(
    ["/assessmnet-logs", id, userID, parsed.user_ID],
    () => getCandidateLogs(id, userID, parsed.user_ID)
  );
  //#endregion

  //#region hiring dropdown toggle
  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  //#endregion

  //#region handling hiring modal outside click
  //#region handle outside clicks
  useOutsideClick(
    [
      {
        ref: hiringRef,
        excludeIds: ["buttonID", "dropID"],
      },
      {
        ref: threedotsRef,
        excludeClasses: [".fieldclass"],
        excludeIds: ["buttonID", "dropID"],
      },
      {
        ref: downloadRef,
        excludeClasses: [".downloadClass"],
        excludeIds: ["downloadID", "labelID"],
      },
      {
        ref: graphRef,
        excludeClasses: [".graphClass"],
        excludeIds: ["graphID"],
      },
    ],
    (ref) => {
      if (ref === downloadRef) setDownloadDropdown(false);
      if (ref === hiringRef) setIsDropdownOpen(false);
      if (ref === threedotsRef) setdropsettings(false);
      if (ref === graphRef) setGraphDropdown(false);
    }
  );
  //#endregion

  useEffect(() => {
    function handleoutsideeducation(event) {
      if (
        hiringRef.current &&
        !hiringRef.current.contains(event.target) &&
        event.target.id !== "buttonID" &&
        event.target.id !== "dropID"
      ) {
        setIsDropdownOpen(false);
      }
    }
    document.addEventListener("mousedown", handleoutsideeducation);
    return () => {
      document.removeEventListener("mousedown", handleoutsideeducation);
    };
  }, [hiringRef]);

  useEffect(() => {
    function handleoutsideeducation(event) {
      if (
        threedotsRef.current &&
        !threedotsRef.current.contains(event.target) &&
        event.target.id !== "buttonID" &&
        event.target.id !== "dropID" &&
        !event.target.closest(".fieldclass")
      ) {
        setdropsettings(false);
      }
    }
    document.addEventListener("mousedown", handleoutsideeducation);
    return () => {
      document.removeEventListener("mousedown", handleoutsideeducation);
    };
  }, [hiringRef]);

  //#endregion

  //#region convert time to hh:mm:ss format
  const convertToHHMMSS = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${(hours * 60 + remainingMinutes).toString()}`;
  };

  const convertSecondsToMin = (s) => +s / 60;

  function convertTimeToMinutes(timeDifference) {
    const [hours, minutes, seconds] = timeDifference.split(":").map(Number);

    const totalMinutes = hours * 60 + minutes + seconds / 60;

    return +totalMinutes;
  }

  const convertToMinutes = (timestamp) => {
    if (!timestamp) return "";

    const [hours, minutes, seconds] = timestamp.split(":").map(Number);

    if (hours > 0) {
      // If there are hours, convert to total minutes
      const totalMinutes = hours * 60 + minutes;
      return `${totalMinutes}m`;
    } else if (minutes > 0) {
      // If there are minutes, display minutes and optionally seconds
      if (seconds > 0) {
        return `${minutes}m ${seconds}s`;
      } else {
        return `${minutes}m`;
      }
    } else {
      // If only seconds are present
      return `${seconds}s`;
    }
  };

  //#endregion

  //#region slider image change
  const handleSliderChange = (event) => {
    setImageLoading(true);
    setCurrentImageIndex(parseInt(event.target.value));
    setTimeout(() => {
      setImageLoading(false);
    }, 2000);
  };
  //#endregion

  //#region get graphs Data
  const { data: performanceData, isLoading: performanceLoading } = useQuery(
    ["performance-insight-graph-data", id, parsed.user_ID],
    () => getPerformanceData(id, parsed.user_ID)
  );

  const { data: completionData, isLoading: completionLoading } = useQuery(
    ["completion-time-insight-graph-data", id, parsed.user_ID],
    () => getCompletionData(id, parsed.user_ID)
  );
  //#endregion

  //#region settings scores and times array
  useEffect(() => {
    if (performanceData) {
      const { numberOfCandidates, scores } = performanceData;
      let result = [];

      for (let i = 0; i < scores.length; i++) {
        const score = Math.floor(scores[i]);
        const count = numberOfCandidates[i];

        const scoreArray = Array(count).fill(score);
        result = result.concat(scoreArray);
      }

      setScoresArray(result);
    }
  }, [performanceData]);

  useEffect(() => {
    if (completionData) {
      const { numberOfCandidates, completionTimes } = completionData;
      let result = [];

      for (let i = 0; i < completionTimes.length; i++) {
        const time = Math.floor(completionTimes[i]);
        const count = numberOfCandidates[i];

        const timeArray = Array(count).fill(time);
        result = result.concat(timeArray);
      }

      setTimeArray(result);
    }
  }, [completionData]);
  //#endregion

  //#region taking user to top of screen
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //#endregion

  //#region handling when to open premium modal
  const handleGraphFilterClick = (title) => {
    if (
      user_package_check !== "Enterprise" &&
      (title === "Dexta candidate pool average" ||
        title === "Dexta best performing candidate")
    ) {
      setPremiumOpen(true);
    } else {
      setSelectedGraphFilter(title);
      setPremiumOpen(false); // Reset premiumOpen state
    }
    setGraphDropdown(false);
  };
  //#endregion

  document.title = "Invite Candidates | Dexta";
  return (
    <>
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        enableMultiContainer={false}
        limit={1}
      />
      <RejectCandidate
        rejectOpen={rejectOpen}
        setRejectOpen={setRejectOpen}
        firstName={data?.candidate?.candidates?.firstName}
        lastName={data?.candidate?.candidates?.lastName}
      />
      <PassCandidate
        passOpen={passOpen}
        setPassOpen={setPassOpen}
        firstName={data?.candidate?.candidates?.firstName}
        lastName={data?.candidate?.candidates?.lastName}
      />
      <Premium premiumOpen={premiumOpen} setPremiumOpen={setPremiumOpen} />
      <HiringModal
        hiringOpen={hiringOpen}
        setHiringOpen={setHiringOpen}
        hiringLabel={hiringLabel}
        hiringStatus={hiringStatus}
        checkboxText={checkboxText}
        typeHiring={typeHiring}
        firstName={data?.candidate?.candidates?.firstName}
        lastName={data?.candidate?.candidates?.lastName}
      />
      <div className="mt-20">
        <div className="bg-bodyColor">
          <div className="lg:container bg-bodyColor mx-auto lg:px-0 sm:px-4">
            <div className="pt-10 mt-10 w-full">
              <div className="py-6 bg-coalColor rounded-lg">
                <div className="flex md:flex-row sm:flex-col md:justify-between px-5 md:gap-0 sm:gap-5">
                  <div className="flex flex-row gap-8 text-white">
                    {backCheck ? (
                      <IoChevronBackCircleOutline
                        className="w-5 h-5 cursor-pointer my-auto"
                        onClick={() => {
                          dispatch(setCandidateToFalse(false));
                          navigate(`/candidates`);
                        }}
                      />
                    ) : (
                      <IoChevronBackCircleOutline
                        className="w-5 h-5 my-auto cursor-pointer"
                        onClick={() => navigate(`/invite-candidates/${id}`)}
                      />
                    )}
                    <div className="flex md:flex-row md:gap-8 sm:gap-0 sm:flex-col">
                      <p style={{ fontFamily: "Silka Bold" }}>
                        {data?.candidate?.candidates?.firstName}{" "}
                        {data?.candidate?.candidates?.lastName}
                      </p>
                      <p style={{ fontFamily: "Silka" }}>
                        {data?.candidate?.candidates?.email}
                      </p>
                    </div>
                  </div>
                  <div className="flex md:flex-row gap-8 sm:flex-row-reverse sm:mr-auto md:mr-0">
                    {data?.candidate?.status === "completed" &&
                      user_role !== "hiring-manager" && (
                        <>
                          <div className="flex flex-col gap-3 my-auto relative">
                            <p
                              style={{ fontFamily: "Silka" }}
                              className="underline text-white cursor-pointer"
                              id="labelID"
                              onClick={() =>
                                setDownloadDropdown(!downloadDropdown)
                              }
                            >
                              Download results
                            </p>
                            {downloadDropdown && (
                              <div
                                id="education-drop"
                                className={`downloadClass absolute z-20 border w-[15rem] ${
                                  isMobile ? "left-0" : "right-0"
                                } border-coalColor top-10 h-auto overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] mt-2`}
                              >
                                <ul
                                  id="downloadID"
                                  ref={downloadRef}
                                  className="text-sm text-coalColor hover:bg-coalColor hover:text-white cursor-pointer"
                                  style={{ fontFamily: "Silka" }}
                                >
                                  <li>
                                    <a
                                      href={
                                        process.env.REACT_APP_Server +
                                        `/assessment/candidate/result/download/assessment/${id}/user/${parsed.user_ID}?download=pdf`
                                      }
                                      className="block px-5 py-2 text-sm font-medium"
                                    >
                                      Download PDF
                                    </a>
                                  </li>
                                </ul>
                                <ul
                                  id="downloadID"
                                  ref={downloadRef}
                                  className="text-sm text-coalColor hover:bg-coalColor hover:text-white cursor-pointer"
                                  style={{ fontFamily: "Silka" }}
                                >
                                  <li>
                                    <a
                                      href={
                                        process.env.REACT_APP_Server +
                                        `/assessment/candidate/result/download/assessment/${id}/user/${parsed.user_ID}?download=csv`
                                      }
                                      className="block px-5 py-2 text-sm font-medium"
                                    >
                                      Download CSV
                                    </a>
                                  </li>
                                </ul>
                                <ul
                                  id="downloadID"
                                  ref={downloadRef}
                                  className="text-sm text-coalColor hover:bg-coalColor hover:text-white cursor-pointer"
                                  style={{ fontFamily: "Silka" }}
                                >
                                  <li>
                                    <a
                                      className="block px-5 py-2 text-sm font-medium"
                                      href={
                                        process.env.REACT_APP_Server +
                                        `/assessment/candidate/result/download/assessment/${id}/user/${parsed.user_ID}?download=xlsx`
                                      }
                                    >
                                      Download XLSX
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                          <div className="flex flex-col gap-3 my-auto relative">
                            <PiDotsThreeCircle
                              id="buttonID"
                              className="w-5 h-5 my-auto cursor-pointer text-white"
                              onClick={() => setdropsettings(!dropsettings)}
                            />
                            {dropsettings && (
                              <div
                                id="education-drop"
                                className={`fieldclass absolute z-20 border w-[15rem] ${
                                  isMobile ? "left-0" : "right-0"
                                } border-coalColor top-10 h-auto overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] mt-2`}
                              >
                                <ul
                                  id="dropID"
                                  ref={threedotsRef}
                                  className="text-sm text-coalColor hover:bg-coalColor hover:text-white cursor-pointer"
                                  style={{ fontFamily: "Silka" }}
                                >
                                  <li>
                                    <p
                                      className="block px-5 py-2 text-sm font-medium"
                                      onClick={() => {
                                        setDeleteModal(true);
                                      }}
                                    >
                                      Delete candidate
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {isLoading || performanceLoading ? (
          <div class="loader-container-1 col-span-6">
            <div class="loader-1"></div>
          </div>
        ) : (
          <div className="grid md:grid-cols-2 mt-5 md:container mx-auto bg-white p-4">
            {/* my stats */}
            <div>
              {/* my stats */}
              <div className="border border-[#D3D5D8] bg-[#F8F8F4] rounded-lg">
                <p
                  className="text-coalColor text-base 2xl:text-lg flex flex-row justify-between px-5 border-b py-4"
                  style={{ fontFamily: "Archia Semibold" }}
                >
                  {/* Test:  */}
                  <span>Overall Score: {data?.assessment?.name}</span>
                  <span
                    className="underline text-xs 2xl:text-sm  my-auto cursor-pointer"
                    onClick={() => setHelp(true)}
                  >
                    Interpret results
                  </span>
                </p>
                <div className="px-5 mt-5">
                  <div className="py-5">
                    {data?.candidate?.status != "completed" ? (
                      <div className="align-center text-center flex justify-center relative">
                        <p className="my-auto" style={{ fontFamily: "Silka" }}>
                          <div className="grid md:grid-cols-2">
                            <div className="pt-20 pb-5 sm:px-4 md:px-8 bg-[#DBD9C0] rounded flex flex-row sm:gap-14 md:gap-20">
                              <VerticalBar
                                heightOfCell="h-80"
                                EmptyCellColor="bg-[#FFF8E8]"
                                FilledCellColor="bg-[#FF5812]"
                                FilledCellHeight={50}
                                tooltip1Text={
                                  data?.candidate?.candidates?.firstName + "`s"
                                }
                                tooltip2Text="total score"
                                tooltip3Text={50 + "%"}
                                paddingTooltip="py-3"
                                marginTopTooltip="-mt-[100px]"
                                widthtooltip="w-[7rem]"
                              />
                              <VerticalBar
                                heightOfCell="h-80"
                                EmptyCellColor="bg-[#FFF8E8]"
                                FilledCellHeight={50}
                                tooltip1Text="Your candidate"
                                FilledCellColor="bg-[#C0FF06]"
                                tooltip2Text="pool average score"
                                tooltip3Text={50 + "%"}
                                paddingTooltip="py-3"
                                marginTopTooltip="-mt-[100px]"
                                widthtooltip="w-[7rem]"
                              />
                              <VerticalBar
                                heightOfCell="h-80"
                                EmptyCellColor="bg-[#FFF8E8]"
                                FilledCellHeight={50}
                                tooltip1Text="Highest"
                                FilledCellColor="bg-[#FFB500]"
                                tooltip2Text="candidate score"
                                tooltip3Text={50 + "%"}
                                paddingTooltip="py-3"
                                marginTopTooltip="-mt-[100px]"
                                widthtooltip="w-[7rem]"
                              />
                            </div>
                            <div className="py-8 space-y-3 2xl:ml-5">
                              <div className="flex flex-row gap-2 sm:px-2 md:px-6">
                                <p
                                  className="sm:text-xs md:text-[12px] 2xl:text-[16px]"
                                  style={{ fontFamily: "Archia Bold" }}
                                >
                                  Invited:
                                </p>
                                <p
                                  className="sm:text-xs md:text-[12px] 2xl:text-[16px]"
                                  style={{ fontFamily: "Silka" }}
                                >
                                  {moment(data?.Invited).format(
                                    "MMMM Do, YYYY"
                                  )}
                                </p>
                              </div>
                              <div className="flex flex-row gap-2 sm:px-2 md:px-6">
                                <p
                                  className="text-[12px] 2xl:text-[16px]"
                                  style={{ fontFamily: "Archia Bold" }}
                                >
                                  Completed:
                                </p>
                                <p
                                  className="text-[12px] 2xl:text-[16px]"
                                  style={{ fontFamily: "Silka" }}
                                >
                                  {data?.candidate?.status != "completed" ? (
                                    <p>N/A</p>
                                  ) : (
                                    <p>
                                      {moment
                                        .unix(data?.completed)
                                        .format("MMMM Do, YYYY")}
                                    </p>
                                  )}
                                </p>
                              </div>
                              <div className="flex flex-row gap-2 sm:px-2 md:px-6">
                                <p
                                  className="text-[12px] 2xl:text-[16px]"
                                  style={{ fontFamily: "Archia Bold" }}
                                >
                                  Invite channel:
                                </p>
                                <p
                                  className="text-[12px] 2xl:text-[16px]"
                                  style={{ fontFamily: "Silka" }}
                                >
                                  {
                                    (data.source =
                                      data.source === "email"
                                        ? "Email invite"
                                        : data.source)
                                  }
                                </p>
                              </div>
                              <div className="sm:px-2 md:px-6 text-left">
                                <p
                                  className="text-[12px] 2xl:text-[16px] mt-10"
                                  style={{ fontFamily: "Archia Bold" }}
                                >
                                  Hiring stage:
                                </p>
                                <div
                                  className="relative mt-4"
                                  data-te-dropdown-ref
                                  ref={hiringRef}
                                >
                                  <button
                                    id="buttonID"
                                    className={`items-center px-4 text-sm 2xl:text-base py-3 rounded-md bg-[#FFFFFF] text-[#7C8289] font-medium w-full flex justify-between border border-[#D3D5D8]`}
                                    type="button"
                                    data-te-dropdown-toggle-ref
                                    aria-expanded={isDropdownOpen}
                                    data-te-ripple-init
                                    disabled={user_role === "hiring-manager"}
                                    data-te-ripple-color="light"
                                    onClick={() => {
                                      if (
                                        data?.candidate?.status === "completed"
                                      ) {
                                        if (
                                          data?.candidate?.isFinalizedEmailSent
                                        ) {
                                          switch (
                                            data?.candidate?.hiringStage
                                          ) {
                                            case "HIRED":
                                              toast.error(
                                                "You cannot change the hiring stage of a candidate who has been sent hiring email.",
                                                {
                                                  toastId: "copy-success",
                                                }
                                              );
                                              break;
                                            case "REJECTED":
                                              toast.error(
                                                "You cannot change the hiring stage of a candidate who has been sent rejection email.",
                                                {
                                                  toastId: "copy-success",
                                                }
                                              );
                                              break;
                                            default:
                                              handleDropdownToggle();
                                          }
                                        } else {
                                          handleDropdownToggle();
                                        }
                                      } else {
                                        switch (data?.candidate?.status) {
                                          case "InProgress":
                                            toast.error(
                                              "The candidate's test is still in progress.",
                                              {
                                                toastId: "copy-success",
                                              }
                                            );
                                            break;
                                          case "started":
                                            toast.error(
                                              "The candidate's test is still in progress.",
                                              {
                                                toastId: "copy-success",
                                              }
                                            );
                                            break;
                                          case "invited":
                                            toast.error(
                                              "The candidate has been invited but has not completed the test yet.",
                                              {
                                                toastId: "copy-success",
                                              }
                                            );
                                            break;
                                          default:
                                            break;
                                        }
                                      }
                                    }}
                                    style={{ fontFamily: "Silka" }}
                                  >
                                    {data?.candidate?.hiringStage ===
                                    "INTERVIEWED"
                                      ? "Interview Completed"
                                      : data?.candidate?.hiringStage ===
                                        "REJECTED"
                                      ? "Candidate Rejected"
                                      : data?.candidate?.hiringStage
                                          .toLowerCase()
                                          .split(" ")
                                          .map(
                                            (word) =>
                                              word.charAt(0).toUpperCase() +
                                              word.slice(1)
                                          )
                                          .join(" ")}
                                    {data?.candidate?.isFinalizedEmailSent ? (
                                      <IoIosMail className="w-5 h-5 ml-auto my-auto" />
                                    ) : (
                                      <svg
                                        className="w-2.5 h-2.5 ml-2"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 10 6"
                                      >
                                        <path
                                          stroke="currentColor"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="m1 1 4 4 4-4"
                                        />
                                      </svg>
                                    )}
                                  </button>
                                  {isDropdownOpen && (
                                    <ul
                                      id="dropID"
                                      className="absolute z-40 border border-coalColor right-0 top-full h-[15rem] overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                                      aria-labelledby="dropdownMenuButton2"
                                      data-te-dropdown-menu-ref
                                    >
                                      {hiringData?.hiring_stage
                                        ?.slice(1)
                                        ?.map((i) => (
                                          <li
                                            className="text-sm text-coalColor rounded px-5 py-2 hover:bg-coalColor hover:text-white cursor-pointer"
                                            style={{ fontFamily: "Silka" }}
                                            onClick={() => {
                                              handlehiringChange(i);
                                              handleDropdownToggle();
                                            }}
                                          >
                                            {i === "INTERVIEWED"
                                              ? "Interview Completed"
                                              : i === "REJECTED"
                                              ? "Candidate Rejected"
                                              : i
                                                  .toLowerCase()
                                                  .split(" ")
                                                  .map(
                                                    (word) =>
                                                      word
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                      word.slice(1)
                                                  )
                                                  .join(" ")}
                                          </li>
                                        ))}
                                    </ul>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </p>
                        <div class="absolute inset-0 flex justify-center items-center z-20 backdrop-blur-lg">
                          <div class="p-8 bg-coalColor h-40 w-5/6 rounded-lg flex flex-col justify-center items-center">
                            <img src={Lock} class="w-8 h-9" />
                            <p
                              class="text-center text-white mt-2"
                              style={{ fontFamily: "Silka Light" }}
                            >
                              Analysis will be made available once candidate
                              completes their test
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="grid md:grid-cols-2">
                        <div className="pt-20 pb-5 sm:px-4 md:px-8 bg-[#DBD9C0] rounded flex flex-row gap-14 2xl:gap-20">
                          <VerticalBar
                            heightOfCell="h-80"
                            EmptyCellColor="bg-[#FFF8E8]"
                            FilledCellColor="bg-[#FF5812]"
                            FilledCellHeight={parseInt(data?.AvgScore)}
                            tooltip1Text={`${data?.candidate?.candidates?.firstName}'s`}
                            tooltip2Text="total score"
                            tooltip3Text={`${Number(
                              data?.AvgScore || 0
                            ).toFixed(0)}%`}
                            paddingTooltip="py-1 2xl:py-3"
                            marginTopTooltip="-mt-[100px]"
                            widthtooltip="w-[6rem] 2xl:w-[7rem]"
                          />
                          <VerticalBar
                            heightOfCell="h-80"
                            EmptyCellColor="bg-[#FFF8E8]"
                            FilledCellHeight={parseInt(
                              data?.averageScoreOfAllCandidates
                            )}
                            tooltip1Text="Your candidate"
                            FilledCellColor="bg-[#C0FF06]"
                            tooltip2Text="pool average score"
                            tooltip3Text={`${Number(
                              data?.averageScoreOfAllCandidates || 0
                            ).toFixed(0)}%`}
                            paddingTooltip="py-1 2xl:py-3"
                            marginTopTooltip="-mt-[100px]"
                            widthtooltip="w-[6rem] 2xl:w-[7rem]"
                          />
                          <VerticalBar
                            heightOfCell="h-80"
                            EmptyCellColor="bg-[#FFF8E8]"
                            FilledCellHeight={parseInt(data?.highestScore)}
                            tooltip1Text="Highest"
                            FilledCellColor="bg-[#FFB500]"
                            tooltip2Text="candidate score"
                            tooltip3Text={`${Number(
                              data?.highestScore || 0
                            ).toFixed(0)}%`}
                            paddingTooltip="py-1 2xl:py-3"
                            marginTopTooltip="-mt-[100px]"
                            widthtooltip="w-[6rem] 2xl:w-[7rem]"
                          />
                        </div>
                        <div className="py-8 space-y-3 2xl:ml-5">
                          <div className="flex flex-row gap-2 sm:px-2 md:px-0 md:pl-6 sm:pl-0">
                            <p
                              className="text-[12px] 2xl:text-[16px]"
                              style={{ fontFamily: "Archia Bold" }}
                            >
                              Invited:
                            </p>
                            <p
                              className="text-[12px] 2xl:text-[16px]"
                              style={{ fontFamily: "Silka" }}
                            >
                              {moment(data?.Invited).format("MMMM Do, YYYY")}
                            </p>
                          </div>
                          <div className="flex flex-row gap-2 sm:px-2 md:px-0 md:pl-6 sm:pl-0">
                            <p
                              className="text-[12px] 2xl:text-[16px]"
                              style={{ fontFamily: "Archia Bold" }}
                            >
                              Completed:
                            </p>
                            <p
                              className="text-[12px] 2xl:text-[16px]"
                              style={{ fontFamily: "Silka" }}
                            >
                              {data?.candidate?.status != "completed" ? (
                                <p>N/A</p>
                              ) : (
                                <p>
                                  {moment
                                    .unix(data?.completed)
                                    .format("MMMM Do, YYYY")}
                                </p>
                              )}
                            </p>
                          </div>
                          <div className="flex flex-row gap-2 sm:px-2 md:px-0 md:pl-6 sm:pl-0">
                            <p
                              className="text-[12px] 2xl:text-[16px]"
                              style={{ fontFamily: "Archia Bold" }}
                            >
                              Invite channel:
                            </p>
                            <p
                              className="text-[12px] 2xl:text-[16px]"
                              style={{ fontFamily: "Silka" }}
                            >
                              {
                                (data.source =
                                  data.source === "email"
                                    ? "Email invite"
                                    : data.source)
                              }
                            </p>
                          </div>
                          <div className="sm:px-2 md:px-0 md:pl-6 sm:pl-0">
                            <p
                              className="mt-10 text-[12px] 2xl:text-[16px]"
                              style={{ fontFamily: "Archia Bold" }}
                            >
                              Hiring stage:
                            </p>
                            <div
                              className="relative mt-4"
                              data-te-dropdown-ref
                              ref={hiringRef}
                            >
                              <button
                                id="buttonID"
                                className={`items-center px-4 text-sm 2xl:text-base py-3 rounded-md bg-[#FFFFFF] text-[#7C8289] font-medium w-full flex justify-between border border-[#D3D5D8]`}
                                type="button"
                                data-te-dropdown-toggle-ref
                                aria-expanded={isDropdownOpen}
                                data-te-ripple-init
                                disabled={user_role === "hiring-manager"}
                                data-te-ripple-color="light"
                                onClick={() => {
                                  if (data?.candidate?.status === "completed") {
                                    if (data?.candidate?.isFinalizedEmailSent) {
                                      switch (data?.candidate?.hiringStage) {
                                        case "HIRED":
                                          toast.error(
                                            "You cannot change the hiring stage of a candidate who has been sent hiring email.",
                                            {
                                              toastId: "copy-success",
                                            }
                                          );
                                          break;
                                        case "REJECTED":
                                          toast.error(
                                            "You cannot change the hiring stage of a candidate who has been sent rejection email.",
                                            {
                                              toastId: "copy-success",
                                            }
                                          );
                                          break;
                                        default:
                                          handleDropdownToggle();
                                      }
                                    } else {
                                      handleDropdownToggle();
                                    }
                                  } else {
                                    switch (data?.candidate?.status) {
                                      case "InProgress":
                                        toast.error(
                                          "The candidate's test is still in progress.",
                                          {
                                            toastId: "copy-success",
                                          }
                                        );
                                        break;
                                      case "started":
                                        toast.error(
                                          "The candidate's test is still in progress.",
                                          {
                                            toastId: "copy-success",
                                          }
                                        );
                                        break;
                                      case "invited":
                                        toast.error(
                                          "The candidate has been invited but has not completed the test yet.",
                                          {
                                            toastId: "copy-success",
                                          }
                                        );
                                        break;
                                      default:
                                        break;
                                    }
                                  }
                                }}
                                style={{ fontFamily: "Silka" }}
                              >
                                <div className="flex flex-row">
                                  <div
                                    className={`w-3 h-3 my-auto ${
                                      data?.candidate?.hiringStage ===
                                        "NOT YET EVALUATED" && "bg-[#FFB500]"
                                    } ${
                                      data?.candidate?.hiringStage ===
                                        "INVITED FOR INTERVIEW" &&
                                      "bg-primaryGreen"
                                    } ${
                                      data?.candidate?.hiringStage ===
                                        "INTERVIEWED" && "bg-primaryGreen"
                                    } ${
                                      data?.candidate?.hiringStage ===
                                        "REFRENCES CHECKED" && "bg-primaryGreen"
                                    }  ${
                                      data?.candidate?.hiringStage ===
                                        "OFFER SENT" && "bg-green-800"
                                    } 
                                          ${
                                            data?.candidate?.hiringStage ===
                                              "OFFER DECLINED" && "bg-[#FF5812]"
                                          } 
                                          ${
                                            data?.candidate?.hiringStage ===
                                              "CANDIDATE WITHDREW" &&
                                            "bg-[#FF5812]"
                                          } 
                                          ${
                                            data?.candidate?.hiringStage ===
                                              "CANDIDATE UNRESPONSIVE" &&
                                            "bg-[#FF5812]"
                                          } 
                                             ${
                                               data?.candidate?.hiringStage ===
                                                 "REJECTED" && "bg-[#FF5812]"
                                             } 
                                              ${
                                                data?.candidate?.hiringStage ===
                                                  "HIRED" && "bg-primaryGreen"
                                              }  ${
                                      data?.candidate?.hiringStage ===
                                        "PASSED" && "bg-primaryGreen"
                                    } 
                                    ${
                                      data?.candidate?.hiringStage ===
                                        "REMINDER TO RESPOND TO OFFER" &&
                                      "bg-primaryGreen"
                                    } 
                                    ${
                                      data?.candidate?.hiringStage ===
                                        "POSITION WITHDRAWN" && "bg-[#FF5812]"
                                    } 
                                          rounded-full mr-2`}
                                  ></div>

                                  {data?.candidate?.hiringStage ===
                                  "INTERVIEWED"
                                    ? "Interview Completed"
                                    : data?.candidate?.hiringStage ===
                                      "REJECTED"
                                    ? "Candidate Rejected"
                                    : data?.candidate?.hiringStage
                                        .toLowerCase()
                                        .split(" ")
                                        .map(
                                          (word) =>
                                            word.charAt(0).toUpperCase() +
                                            word.slice(1)
                                        )
                                        .join(" ")}
                                </div>
                                {data?.candidate?.isFinalizedEmailSent ? (
                                  <IoIosMail className="w-5 h-5 ml-auto my-auto" />
                                ) : (
                                  <svg
                                    className="w-2.5 h-2.5 ml-2"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 10 6"
                                  >
                                    <path
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="m1 1 4 4 4-4"
                                    />
                                  </svg>
                                )}
                              </button>
                              {isDropdownOpen && (
                                <div
                                  id="dropID"
                                  className="absolute z-40 border border-coalColor  enable-scrollbar2  right-0 top-full h-[15rem] overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                                  aria-labelledby="dropdownMenuButton2"
                                  data-te-dropdown-menu-ref
                                >
                                  <Scrollbars
                                    autoHide
                                    style={{ width: "100%", height: "100%" }}
                                  >
                                    {hiringData?.hiring_stage
                                      ?.slice(1)
                                      ?.map((i) => (
                                        <ul
                                          className="text-sm text-coalColor flex flex-row rounded px-5 py-2 hover:bg-coalColor hover:text-white cursor-pointer"
                                          style={{ fontFamily: "Silka" }}
                                          onClick={() => {
                                            handlehiringChange(i);
                                            handleDropdownToggle();
                                          }}
                                        >
                                          <div
                                            className={`w-3 h-3 my-auto ${
                                              i === "NOT YET EVALUATED" &&
                                              "bg-[#FFB500]"
                                            } ${
                                              i === "INVITED FOR INTERVIEW" &&
                                              "bg-primaryGreen"
                                            } ${
                                              i === "INTERVIEWED" &&
                                              "bg-primaryGreen"
                                            } ${
                                              i === "REFRENCES CHECKED" &&
                                              "bg-primaryGreen"
                                            }  ${
                                              i === "OFFER SENT" &&
                                              "bg-green-800"
                                            } 
                                          ${
                                            i === "OFFER DECLINED" &&
                                            "bg-[#FF5812]"
                                          } 
                                          ${
                                            i === "CANDIDATE WITHDREW" &&
                                            "bg-[#FF5812]"
                                          } 
                                          ${
                                            i === "CANDIDATE UNRESPONSIVE" &&
                                            "bg-[#FF5812]"
                                          } 
                                             ${
                                               i === "REJECTED" &&
                                               "bg-[#FF5812]"
                                             } 
                                              ${
                                                i === "HIRED" &&
                                                "bg-primaryGreen"
                                              }  ${
                                              i === "PASSED" &&
                                              "bg-primaryGreen"
                                            } 
                                           ${
                                             i ===
                                               "REMINDER TO RESPOND TO OFFER" &&
                                             "bg-primaryGreen"
                                           } 
                                    ${
                                      i === "POSITION WITHDRAWN" &&
                                      "bg-[#FF5812]"
                                    } 
                                          rounded-full mr-2`}
                                          ></div>
                                          {i === "INTERVIEWED"
                                            ? "Interview Completed"
                                            : i === "REJECTED"
                                            ? "Candidate Rejected"
                                            : i
                                                .toLowerCase()
                                                .split(" ")
                                                .map(
                                                  (word) =>
                                                    word
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                    word.slice(1)
                                                )
                                                .join(" ")}
                                          {i === "INVITED FOR INTERVIEW" ||
                                          i === "OFFER SENT" ||
                                          i === "REJECTED" ||
                                          i ===
                                            "REMINDER TO RESPOND TO OFFER" ||
                                          i === "HIRED" ||
                                          i === "PASSED" ||
                                          i === "POSITION WITHDRAWN" ? (
                                            <IoIosMail className="ml-auto my-auto w-4 h-4" />
                                          ) : null}
                                        </ul>
                                      ))}
                                  </Scrollbars>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* my anti cheat stats */}
              <div className="border mt-5 border-[#D3D5D8] bg-[#F8F8F4] rounded-lg">
                <p
                  className="text-coalColor px-5 text-base 2xl:text-lg border-b py-4"
                  style={{ fontFamily: "Archia Semibold" }}
                >
                  Anti cheating monitor
                </p>
                <div className="px-5 pt-4 grid grid-cols-12">
                  <div
                    className="col-span-8"
                    style={{ fontFamily: "Archia Semibold" }}
                  >
                    <div>
                      <p className="sm:h-10 md:h-10 ">Device Used</p>
                      <p className="sm:h-10 md:h-10 ">Location</p>
                      <p className="sm:h-14 md:h-10 ">
                        Test completed once from IP address?
                      </p>
                      <p className="sm:h-10 md:h-10 ">Webcam enabled?</p>
                      <p className="sm:h-14 md:h-10 ">
                        Full-screen mode always active?
                      </p>
                      <p className="sm:h-14 md:h-10 ">
                        Mouse always in test window?
                      </p>
                    </div>
                  </div>
                  <div className="col-span-4 ml-auto w-full">
                    <div>
                      <p
                        className="sm:h-10 md:h-10 text-right"
                        style={{ fontFamily: "Silka" }}
                      >
                        {data?.candidate?.status != "completed" ? (
                          <div
                            className={`${
                              data?.candidate?.status !== "completed"
                                ? "w-2/3 md:w-1/2 border-[#FF5812]"
                                : "w-[4rem]"
                            } ml-auto text-center rounded-2xl border 
                                 `}
                            style={{ fontFamily: "Silka" }}
                          >
                            {data?.candidate?.status != "completed" && (
                              <span className="2xl:text-base text-sm">
                                Pending
                              </span>
                            )}
                          </div>
                        ) : (
                          data?.evaloation_Response
                            ?.evaluation_users_response_logs_device
                        )}
                      </p>
                      <p
                        className={`sm:h-10 md:h-10 ${
                          data?.candidate?.status === "completed"
                            ? "w-5/6"
                            : "w-full"
                        } ml-auto text-right truncate`}
                        style={{ fontFamily: "Silka" }}
                        title={
                          data?.candidate?.status === "completed" &&
                          data?.evaloation_Response
                            ?.evaluation_users_response_logs_location
                        }
                      >
                        {data?.candidate?.status != "completed" ? (
                          <div
                            className={`${
                              data?.candidate?.status !== "completed"
                                ? "w-2/3 md:w-1/2 border-[#FF5812]"
                                : "w-[4rem]"
                            } ml-auto text-center rounded-2xl border 
                               `}
                            style={{ fontFamily: "Silka" }}
                          >
                            {data?.candidate?.status != "completed" && (
                              <span className="2xl:text-base text-sm">
                                Pending
                              </span>
                            )}
                          </div>
                        ) : data?.evaloation_Response
                            ?.evaluation_users_response_logs_location ===
                          "United Kingdom of Great Britain and Northern Ireland" ? (
                          "UK"
                        ) : (
                          data?.evaloation_Response
                            ?.evaluation_users_response_logs_location
                        )}
                      </p>
                      <div className="sm:h-14 md:h-10 ">
                        <div
                          className={`${
                            data?.candidate?.status !== "completed"
                              ? "w-2/3 md:w-1/2 border-[#FF5812]"
                              : "w-[4rem]"
                          } ml-auto text-center rounded-2xl border ${
                            data?.evaloation_Response
                              ?.evaluation_users_response_logs_ip != ""
                              ? "border-[#C0FF06]"
                              : "border-[#FF5812]"
                          }`}
                          style={{ fontFamily: "Silka" }}
                        >
                          {data?.candidate?.status != "completed" ? (
                            <span className="2xl:text-base text-sm">
                              Pending
                            </span>
                          ) : (
                            <>
                              {" "}
                              {data?.evaloation_Response
                                ?.evaluation_users_response_logs_ip != ""
                                ? "Yes"
                                : "No"}{" "}
                            </>
                          )}
                        </div>
                      </div>
                      <div className="sm:h-10 md:h-10 ">
                        <div
                          className={`${
                            data?.candidate?.status !== "completed"
                              ? `w-2/3 md:w-1/2`
                              : `${
                                  data?.Webcam === "Not always"
                                    ? "w-[8rem]"
                                    : "w-[4rem]"
                                }`
                          } ml-auto text-center rounded-2xl border ${
                            data?.Webcam === "Yes"
                              ? "border-[#C0FF06]"
                              : "border-[#FF5812]"
                          }`}
                          style={{ fontFamily: "Silka" }}
                        >
                          {data?.candidate?.status != "completed" ? (
                            <span className="2xl:text-base text-sm">
                              Pending
                            </span>
                          ) : (
                            <> {data?.Webcam} </>
                          )}
                        </div>
                      </div>
                      <div className="sm:h-14 md:h-10 ">
                        <div
                          className={`${
                            data?.candidate?.status !== "completed"
                              ? "w-2/3 md:w-1/2"
                              : "w-[4rem]"
                          } ml-auto text-center rounded-2xl border ${
                            data?.full ? "border-[#C0FF06]" : "border-[#FF5812]"
                          }`}
                          style={{ fontFamily: "Silka" }}
                        >
                          {data?.candidate?.status != "completed" ? (
                            <span className="text-left 2xl:text-base text-sm">
                              Pending
                            </span>
                          ) : (
                            <> {data?.full ? "Yes" : "No"} </>
                          )}
                        </div>
                      </div>
                      <div className="sm:h-14 md:h-10 ">
                        <div
                          className={`${
                            data?.candidate?.status !== "completed"
                              ? "w-2/3 md:w-1/2"
                              : "w-[4rem]"
                          } ml-auto text-center rounded-2xl border ${
                            data?.mouse &&
                            data?.evaloation_Response
                              ?.evaluation_users_response_logs_device !==
                              "Mobile"
                              ? "border-[#C0FF06]"
                              : "border-[#FF5812]"
                          }`}
                          style={{ fontFamily: "Silka" }}
                        >
                          {data?.candidate?.status != "completed" ? (
                            <span className="text-left 2xl:text-base text-sm">
                              Pending
                            </span>
                          ) : (
                            <>
                              {data?.evaloation_Response
                                ?.evaluation_users_response_logs_device ===
                              "Mobile" ? (
                                "N/A"
                              ) : (
                                <> {data?.mouse ? "Yes" : "No"} </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-5 md:grid grid-cols-2">
                  <div>
                    <p style={{ fontFamily: "Archia Semibold" }}>
                      Images from webcam
                    </p>
                  </div>
                  <div
                    className={`overflow-hidden w-full 
                     rounded-lg relative`}
                  >
                    {/* Image */}
                    {!data?.evaloation_Response?.proctoringSnapshots.length && (
                      <div
                        className={`ml-auto text-right 
                                `}
                        style={{ fontFamily: "Silka" }}
                      >
                        {data?.candidate?.status === "completed" ? (
                          "No images available"
                        ) : (
                          <div
                            className={`${
                              data?.candidate?.status !== "completed"
                                ? "w-full sm:max-md:mt-3 md:w-1/3 border-[#FF5812]"
                                : "w-[4rem]"
                            } ml-auto text-center rounded-2xl border 
                               `}
                            style={{ fontFamily: "Silka" }}
                          >
                            {data?.candidate?.status != "completed" && (
                              <span className="2xl:text-base text-sm">
                                Pending
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    {/* Slider */}
                    {data?.candidate?.status === "completed" && (
                      <div className="absolute bottom-0 left-0 right-0 flex items-center justify-center px-6 pb-4">
                        <IOSSlider
                          min={0}
                          max={
                            data?.evaloation_Response?.proctoringSnapshots
                              ?.length - 1
                          }
                          aria-label="Temperature"
                          value={currentImageIndex}
                          step={1}
                          onChange={handleSliderChange}
                        />
                      </div>
                    )}
                  </div>
                  <div>
                    <div className="rounded-lg w-full pb-12">
                      <div className="flex flex-col items-center justify-center">
                        <div
                          className={`overflow-hidden w-full  ${
                            data?.candidate?.status === "completed" &&
                            data?.evaloation_Response?.proctoringSnapshots
                              .length > 0 &&
                            "shadow-lg border border-gray-200"
                          } rounded-lg relative mt-5`}
                        >
                          {/* Image */}
                          {data?.evaloation_Response?.proctoringSnapshots
                            .length > 0 && (
                            <>
                              <img
                                src={
                                  data?.evaloation_Response
                                    ?.proctoringSnapshots[currentImageIndex]
                                }
                                alt={`Image ${currentImageIndex + 1}`}
                                className="w-full"
                              />
                              {ImageLoading && (
                                <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
                                  <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
                                </div>
                              )}
                            </>
                          )}
                          {/* Slider */}
                          {data?.candidate?.status === "completed" && (
                            <div className="absolute bottom-0 left-0 right-0 flex items-center justify-center px-6 pb-4">
                              <IOSSlider
                                min={0}
                                max={
                                  data?.evaloation_Response?.proctoringSnapshots
                                    ?.length - 1
                                }
                                aria-label="Temperature"
                                value={currentImageIndex}
                                step={1}
                                onChange={handleSliderChange}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* my activity logs*/}
              <div className="border mt-5 border-[#D3D5D8] bg-[#F8F8F4] rounded-lg">
                <p
                  className="text-coalColor px-5 text-base 2xl:text-lg border-b py-4"
                  style={{ fontFamily: "Archia Semibold" }}
                >
                  Activity log
                </p>
                <div className="py-4 pr-5">
                  <div class="enable-scrollbar2 overflow-y-auto pb-5">
                    <Scrollbars
                      autoHeight
                      autoHide
                      autoHeightMax="450px"
                      style={{ width: "100%", height: "100%" }}
                    >
                      {logsData?.map((i) => (
                        <div class="relative pl-8 sm:pl-32 py-6 group">
                          <div class="flex flex-col items-start mb-1 group-last:before:hidden before:absolute before:left-1 sm:before:left-0 before:h-full before:px-px before:bg-coalColor sm:before:ml-[3rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-1 sm:after:left-0 after:w-2 after:h-2 after:bg-[#FFB500] after:border-4 after:box-content after:border-[#FFB500] after:rounded-full sm:after:ml-[3rem] after:-translate-x-1/2 after:translate-y-1.5">
                            <p
                              className="text-sm"
                              style={{ fontFamily: "Silka Light" }}
                            >
                              {moment(i?.updatedAt).format("DD.MM.YY h:mmA")}
                            </p>
                            <div
                              className="text-base 2xl:text-lg font-bold"
                              style={{ fontFamily: "Archia Bold" }}
                            >
                              {(i?.action === "ASSESSMENT_CREATED" &&
                                "TEST CREATED") ||
                                (i?.action === "TEST STOPPED" &&
                                  "TEST EXITED") ||
                                (i?.action === "INVITE_SENT" &&
                                  "INVITE SENT") ||
                                (i?.action === "Resumed" && "TEST RESUMED") ||
                                (i?.action === "REMINDER_SENT" &&
                                  "REMINDER SENT") ||
                                (i?.action === "ASSESSMENT_NOTES" &&
                                  "TEST NOTES") ||
                                (i?.action ===
                                  "UPDATED_CUSTOMER_HIRING_STATUS" &&
                                  "CANDIDATE HIRING STATUS UPDATED") ||
                                (i?.action === "ASSESSMENT_UPDATED" &&
                                  "TEST UPDATED") ||
                                (i?.action === "ASSESSMENT_STARTED" &&
                                  "TEST STARTED") ||
                                (i?.action === "ASSESSMENT_COMPLETED" &&
                                  "TEST COMPLETED") ||
                                (i?.action === "REMINIDER_SENT" &&
                                  "REMINDER SENT") ||
                                i?.action?.toUpperCase()}
                            </div>
                          </div>
                          <div
                            class="text-coalColor text-sm font-medium"
                            style={{ fontFamily: "Silka Light" }}
                          >
                            {i?.actionData}
                          </div>
                        </div>
                      ))}
                    </Scrollbars>
                  </div>
                </div>
              </div>
            </div>
            {/* stats breakdown in modules*/}
            <div>
              {data?.candidate?.status != "completed" ? (
                <div className="relative">
                  <div className="flex md:flex-row sm:flex-col md:justify-between">
                    <p
                      className="text-coalColor px-5 text-base 2xl:text-lg py-4 w-full "
                      style={{ fontFamily: "Archia Semibold" }}
                    >
                      {/* {data?.candidate?.candidates?.firstName}'s Test results */}
                      Module Scores
                    </p>
                    <div
                      className="my-auto grid grid-cols-12 w-full sm:justify-center md:justify-end"
                      style={{ fontFamily: "Silka" }}
                    >
                      <p
                        className="my-auto md:mr-5 sm:mr-2 text-xs 2xl:text-sm w-full col-span-2"
                        style={{ fontFamily: "Silka" }}
                      >
                        Compare to
                      </p>
                      <div className="relative md:w-full col-span-10">
                        <CustomButton
                          label={selectedGraphFilter}
                          id="graphID"
                          borderCustom="border border-black graphClass sm:max-md:text-xs"
                          paddingY="0.3rem"
                          iconR={RiArrowDropDownLine}
                          iconWidth="w-6 h-6"
                          justifyContent="justify-between"
                          paddingx="px-2"
                          onClickButton={() => setGraphDropdown(!graphDropdown)}
                        />
                        {graphDropdown && (
                          <div
                            className="absolute z-40 border border-coalColor graphClass right-0 top-full h-auto overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                            ref={graphRef}
                          >
                            {graph_filters?.map((i) => (
                              <DropdownList
                                key={i?.title}
                                title={i?.title}
                                onClick={() => handleGraphFilterClick(i?.title)}
                                user_package={user_package_check}
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="grid sm:grid-cols-1 md:grid-cols-3 px-5 gap-3 mt-3">
                    {dummy_sections?.map((i, index) => (
                      <div>
                        <div className="bg-[#DBD9C0] h-[19rem] rounded flex flex-col">
                          <p
                            className="px-5 mt-3 h-20 text-base"
                            style={{ fontFamily: "Silka Light" }}
                          >
                            {i?.name}
                          </p>
                          <div className="grid grid-cols-2 px-5 gap-3">
                            <p
                              className={`border rounded-2xl text-xs bg-white my-auto py-1 text-center ${
                                i?.experience === "Beginner" &&
                                "border-[#0B5B23]"
                              } ${
                                i?.experience === "Intermediate" &&
                                "border-[#FFB500]"
                              } ${
                                i?.experience === "Advanced" &&
                                "border-[#FF5812]"
                              } 
                              ${
                                i?.experience === "General" &&
                                "border-coalColor"
                              } 
                              ${i?.experience === "" && "border-[#C0FF06]"}`}
                              style={{ fontFamily: "Silka" }}
                            >
                              {i?.experience != ""
                                ? i?.experience === "General"
                                  ? "All levels"
                                  : i?.experience
                                : "custom"}
                            </p>
                            <div>
                              <p
                                className="text-center text-xs py-1 flex"
                                style={{ fontFamily: "Silka" }}
                              >
                                {i?.time}
                              </p>
                            </div>
                          </div>
                          <div className="flex-row gap-20 mt-20 px-10 flex justify-center ">
                            <VerticalBar
                              heightOfCell="h-20"
                              EmptyCellColor="bg-[#DBD9C0]"
                              FilledCellColor="bg-[#FF5812]"
                              FilledCellHeight={50}
                              tooltip1Text="Scored"
                              tooltip3Text={50 + "%"}
                              paddingTooltip="py-2"
                              marginTopTooltip="-mt-[70px]"
                              widthtooltip="w-[6rem]"
                            />
                            {i?.experience !== "" && (
                              <>
                                {selectedGraphFilter ===
                                  "Your best performing candidate" && (
                                  <VerticalBar
                                    filter={selectedGraphFilter}
                                    heightOfCell="h-20"
                                    EmptyCellColor="bg-[#DBD9C0]"
                                    FilledCellColor="bg-[#FFB500]"
                                    FilledCellHeight={60}
                                    tooltip1Text="Highest score"
                                    tooltip3Text={60 + "%"}
                                    paddingTooltip="py-2"
                                    marginTopTooltip="-mt-[70px]"
                                    widthtooltip="w-[6rem]"
                                  />
                                )}
                                {selectedGraphFilter ===
                                  "Your candidate pool average" && (
                                  <VerticalBar
                                    filter={selectedGraphFilter}
                                    heightOfCell="h-20"
                                    EmptyCellColor="bg-[#DBD9C0]"
                                    FilledCellColor="bg-[#C0FF06]"
                                    FilledCellHeight={70}
                                    tooltip1Text="Average"
                                    tooltip3Text={70 + "%"}
                                    paddingTooltip="py-2"
                                    marginTopTooltip="-mt-[70px]"
                                    widthtooltip="w-[6rem]"
                                  />
                                )}
                                {selectedGraphFilter ===
                                  "Dexta candidate pool average" && (
                                  <VerticalBar
                                    filter={selectedGraphFilter}
                                    heightOfCell="h-20"
                                    EmptyCellColor="bg-[#DBD9C0]"
                                    FilledCellColor="bg-[#252E3A]"
                                    FilledCellHeight={40}
                                    tooltip1Text="Dexta average"
                                    tooltip3Text={40 + "%"}
                                    paddingTooltip="py-2"
                                    marginTopTooltip="-mt-[70px]"
                                    widthtooltip="w-[6rem]"
                                  />
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <p
                    className="text-coalColor px-5 py-4 text-base 2xl:text-lg  mt-4 w-full"
                    style={{ fontFamily: "Archia Semibold" }}
                  >
                    Candidate Pool Comparison
                  </p>
                  <div className="px-5">
                    <div className="relative">
                      <div className="mt-2 px-3 py-2 bg-[#DBD9C0] rounded-lg">
                        {isMobile ? (
                          <>
                            {scoresArray.length > 4 ? (
                              <>
                                <p style={{ fontFamily: "Silka" }}>
                                  Would you like to view Score Distribution
                                  Analysis?
                                </p>
                                <div className="mt-5">
                                  <CustomButton
                                    label="Click here"
                                    textSize="font-medium"
                                    hoverBgColor="#252E3A"
                                    hoverTextColor="#FFFFFF"
                                    bgColor="#C0FF06"
                                    widthButton="w-full"
                                    borderCustom="border border-coalColor text-coalColor"
                                    onClickButton={() => {
                                      dispatch(
                                        setGraphData({
                                          xArray: scoresArray,
                                          myScore:
                                            performanceData?.candidateScore,
                                          firstName:
                                            data?.candidate?.candidates
                                              ?.firstName,
                                          lastName:
                                            data?.candidate?.candidates
                                              ?.lastName,
                                        })
                                      );
                                      navigate("/score-distribution-graph");
                                    }}
                                  />
                                </div>
                              </>
                            ) : (
                              <BoxPlotChart
                                xArray={scoresArray_dummy}
                                myScore={20}
                                firstName={
                                  data?.candidate?.candidates?.firstName
                                }
                                lastName={data?.candidate?.candidates?.lastName}
                                avgScore={50}
                              />
                            )}
                          </>
                        ) : (
                          <>
                            {scoresArray.length > 4 ? (
                              <BoxPlotChart
                                xArray={scoresArray}
                                myScore={performanceData?.candidateScore}
                                firstName={
                                  data?.candidate?.candidates?.firstName
                                }
                                lastName={data?.candidate?.candidates?.lastName}
                                avgScore={Number(
                                  data?.averageScoreOfAllCandidates || 0
                                ).toFixed(0)}
                              />
                            ) : (
                              <BoxPlotChart
                                xArray={scoresArray_dummy}
                                myScore={20}
                                firstName={
                                  data?.candidate?.candidates?.firstName
                                }
                                lastName={data?.candidate?.candidates?.lastName}
                                avgScore={50}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="relative">
                      <div className="mt-10 px-3 py-2 bg-[#DBD9C0] rounded-lg">
                        {isMobile ? (
                          <>
                            {scoresArray.length > 4 ? (
                              <>
                                <p style={{ fontFamily: "Silka" }}>
                                  Would you like to view Completion Time
                                  Analysis?
                                </p>
                                <div className="mt-5">
                                  <CustomButton
                                    label="Click here"
                                    textSize="font-medium"
                                    hoverBgColor="#252E3A"
                                    hoverTextColor="#FFFFFF"
                                    bgColor="#C0FF06"
                                    widthButton="w-full"
                                    borderCustom="border border-coalColor text-coalColor"
                                    onClickButton={() => {
                                      dispatch(
                                        setGraphData({
                                          xArray: timesArray,
                                          myScore: Number(
                                            completionData?.candidateCompletionTime ||
                                              0
                                          ).toFixed(0),
                                          firstName:
                                            data?.candidate?.candidates
                                              ?.firstName,

                                          lastName:
                                            data?.candidate?.candidates
                                              ?.lastName,
                                        })
                                      );
                                      navigate("/completion-time-graph");
                                    }}
                                  />
                                </div>
                              </>
                            ) : (
                              <BoxPlotChart
                                xArray={scoresArray_dummy}
                                myScore={20}
                                firstName={
                                  data?.candidate?.candidates?.firstName
                                }
                                lastName={data?.candidate?.candidates?.lastName}
                                avgScore={50}
                              />
                            )}
                          </>
                        ) : (
                          <>
                            {scoresArray.length > 4 ? (
                              <BoxPlotChart2
                                xArray={timesArray}
                                myScore={Number(
                                  completionData?.candidateCompletionTime || 0
                                ).toFixed(0)}
                                firstName={
                                  data?.candidate?.candidates?.firstName
                                }
                                lastName={data?.candidate?.candidates?.lastName}
                              />
                            ) : (
                              <BoxPlotChart2
                                xArray={timesArray_dummy}
                                myScore={50}
                                firstName={
                                  data?.candidate?.candidates?.firstName
                                }
                                lastName={data?.candidate?.candidates?.lastName}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="absolute inset-0 flex justify-center  items-center z-20 backdrop-blur-lg">
                    <div class="p-8 bg-coalColor h-40 w-4/5  rounded-lg flex flex-col justify-center items-center">
                      <img src={Lock} class="w-8 h-9" />
                      <p
                        class="text-center text-white mt-2"
                        style={{ fontFamily: "Silka Light" }}
                      >
                        Analysis will be made available once candidate completes
                        their test
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  <div className="flex flex-wrap items-center justify-between w-full">
                    <p
                      className="text-coalColor px-5 text-base 2xl:text-lg w-full sm:w-auto"
                      style={{ fontFamily: "Archia Semibold" }}
                    >
                      {/* {data?.candidate?.candidates?.firstName}'s Test results */}
                      Module Scores{" "}
                    </p>
                    <div className="flex items-center justify-end w-full sm:w-auto">
                      <p
                        className="text-right text-xs 2xl:text-sm w-auto mr-2"
                        style={{ fontFamily: "Silka" }}
                      >
                        Compare to
                      </p>
                      <div
                        className="relative w-[17.5rem]"
                        style={{ fontFamily: "Silka" }}
                      >
                        <CustomButton
                          label={selectedGraphFilter}
                          id="graphID"
                          borderCustom="border border-black graphClass 2xl:text-sm text-xs "
                          paddingY="0.3rem"
                          iconR={RiArrowDropDownLine}
                          iconWidth="w-6 h-6"
                          justifyContent="justify-between"
                          paddingx="px-2"
                          onClickButton={() => {
                            setGraphDropdown(!graphDropdown);
                          }}
                        />
                        {graphDropdown && (
                          <div
                            className="absolute z-40 border border-coalColor graphClass right-0 top-full h-auto overflow-scroll bg-white rounded-lg shadow-[0_3px_10px_rgb(0,0,0,0.2)] w-full mt-2"
                            ref={graphRef}
                          >
                            {graph_filters?.map((i) => (
                              <div
                                className="flex justify-between"
                                key={i?.title}
                              >
                                <DropdownList
                                  title={i?.title}
                                  onClick={() =>
                                    handleGraphFilterClick(i?.title)
                                  }
                                  user_package={user_package_check}
                                />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="grid sm:grid-cols-1 md:grid-cols-3 px-5 gap-3 mt-3">
                    {data?.sections?.map((i, index) => (
                      <div>
                        <div className="bg-[#DBD9C0] h-[19rem] rounded flex flex-col">
                          <p
                            className="px-2 2xl:px-5 mt-3 h-20 text-base"
                            style={{ fontFamily: "Silka Light" }}
                          >
                            {i?.name}
                          </p>
                          <div className="grid grid-cols-2 px-1 2xl:px-5 gap-1 2xl:gap-3">
                            <p
                              className={`border rounded-2xl text-[10px] 2xl:text-xs bg-white my-auto py-1 text-center ${
                                i?.experience === "Beginner" &&
                                "border-[#0B5B23]"
                              } ${
                                i?.experience === "Intermediate" &&
                                "border-[#FFB500]"
                              } ${
                                i?.experience === "Advanced" &&
                                "border-[#FF5812]"
                              } 
                              ${
                                i?.experience === "General" &&
                                "border-coalColor"
                              } 
                              ${i?.experience === "" && "border-[#C0FF06]"}`}
                              style={{ fontFamily: "Silka" }}
                            >
                              {i?.experience != ""
                                ? i?.experience === "General"
                                  ? "All levels"
                                  : i?.experience
                                : "custom"}
                            </p>
                            <div>
                              <p
                                className="text-center text-xs py-1 flex"
                                style={{ fontFamily: "Silka" }}
                              >
                                {i?.timeDifference && (
                                  <>
                                    {
                                      // convertTimeToMinutes(i?.timeDifference)
                                      convertSecondsToMin(
                                        i.userTime || i?.time * 60 + 1
                                      ) > parseInt(i?.time) ? (
                                        <>
                                          {convertToHHMMSS(parseInt(i?.time))}m
                                          / {convertToHHMMSS(parseInt(i?.time))}
                                          m
                                        </>
                                      ) : (
                                        <>
                                          {convertToMinutes(i?.timeDifference)}{" "}
                                          / {convertToHHMMSS(parseInt(i?.time))}
                                          m
                                        </>
                                      )
                                    }
                                  </>
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="flex-row gap-10 2xl:gap-20 mt-20 px-8 2xl:px-10 flex justify-center ">
                            <VerticalBar
                              heightOfCell="h-20"
                              EmptyCellColor="bg-[#DBD9C0]"
                              FilledCellColor="bg-[#FF5812]"
                              FilledCellHeight={parseInt(i?.score)}
                              tooltip1Text="Scored"
                              tooltip3Text={`${Number(i?.score || 0).toFixed(
                                0
                              )}%`}
                              paddingTooltip="py-2"
                              marginTopTooltip="-mt-[70px]"
                              widthtooltip="w-[4.6rem] 2xl:w-[6rem]"
                            />
                            {i?.experience !== "" &&
                              (selectedGraphFilter !==
                                "Dexta candidate pool average" ||
                                selectedGraphFilter !==
                                  "Dexta best performing candidate") && (
                                <>
                                  {selectedGraphFilter ===
                                    "Your best performing candidate" && (
                                    <VerticalBar
                                      filter={selectedGraphFilter}
                                      heightOfCell="h-20"
                                      EmptyCellColor="bg-[#DBD9C0]"
                                      FilledCellColor="bg-[#FFB500]"
                                      FilledCellHeight={parseInt(
                                        i?.sectionHighestScore
                                      )}
                                      tooltip1Text="Highest score"
                                      tooltip3Text={`${Number(
                                        i?.sectionHighestScore || 0
                                      ).toFixed(0)}%`}
                                      paddingTooltip="py-2"
                                      marginTopTooltip="-mt-[70px]"
                                      widthtooltip="w-[4.6rem] 2xl:w-[6rem]"
                                    />
                                  )}
                                  {selectedGraphFilter ===
                                    "Your candidate pool average" && (
                                    <VerticalBar
                                      filter={selectedGraphFilter}
                                      heightOfCell="h-20"
                                      EmptyCellColor="bg-[#DBD9C0]"
                                      FilledCellColor="bg-[#C0FF06]"
                                      FilledCellHeight={parseInt(
                                        i?.yourCandidatePoolAverage
                                      )}
                                      tooltip1Text="Average"
                                      tooltip3Text={`${Number(
                                        i?.yourCandidatePoolAverage || 0
                                      ).toFixed(0)}%`}
                                      paddingTooltip="py-2"
                                      marginTopTooltip="-mt-[70px]"
                                      widthtooltip="w-[4.6rem] 2xl:w-[6rem]"
                                    />
                                  )}
                                  {selectedGraphFilter ===
                                    "Dexta candidate pool average" && (
                                    <VerticalBar
                                      filter={selectedGraphFilter}
                                      heightOfCell="h-20"
                                      EmptyCellColor="bg-[#DBD9C0]"
                                      FilledCellColor="bg-[#252E3A]"
                                      FilledCellHeight={parseInt(
                                        i?.averageScore
                                      )}
                                      tooltip1Text="Dexta average"
                                      tooltip3Text={`${Number(
                                        i?.averageScore || 0
                                      ).toFixed(0)}%`}
                                      paddingTooltip="py-2"
                                      marginTopTooltip="-mt-[70px]"
                                      widthtooltip="w-[4.6rem] 2xl:w-[6rem]"
                                    />
                                  )}
                                  {selectedGraphFilter ===
                                    "Dexta best performing candidate" && (
                                    <VerticalBar
                                      filter={selectedGraphFilter}
                                      heightOfCell="h-20"
                                      EmptyCellColor="bg-[#DBD9C0]"
                                      FilledCellColor="bg-[#A16207]"
                                      FilledCellHeight={
                                        parseInt(i?.averageScore) + 6
                                      }
                                      tooltip1Text="Dexta Best"
                                      tooltip3Text={
                                        parseInt(i?.manualDextaBest)
                                          ? parseInt(i?.manualDextaBest) + "%"
                                          : parseInt(i?.averageScore) + 7 + "%"
                                      }
                                      paddingTooltip="py-2"
                                      marginTopTooltip="-mt-[70px]"
                                      widthtooltip="w-[4.6rem] 2xl:w-[6rem]"
                                    />
                                  )}
                                </>
                              )}

                            {i?.experience === "" &&
                              selectedGraphFilter !==
                                "Dexta candidate pool average" &&
                              selectedGraphFilter !==
                                "Dexta best performing candidate" && (
                                <>
                                  {selectedGraphFilter ===
                                    "Your best performing candidate" && (
                                    <VerticalBar
                                      filter={selectedGraphFilter}
                                      heightOfCell="h-20"
                                      EmptyCellColor="bg-[#DBD9C0]"
                                      FilledCellColor="bg-[#FFB500]"
                                      FilledCellHeight={parseInt(
                                        i?.sectionHighestScore
                                      )}
                                      tooltip1Text="Highest score"
                                      tooltip3Text={`${Number(
                                        i?.sectionHighestScore || 0
                                      ).toFixed(0)}%`}
                                      paddingTooltip="py-2"
                                      marginTopTooltip="-mt-[70px]"
                                      widthtooltip="w-[4.6rem] 2xl:w-[6rem]"
                                    />
                                  )}
                                  {selectedGraphFilter ===
                                    "Your candidate pool average" && (
                                    <VerticalBar
                                      filter={selectedGraphFilter}
                                      heightOfCell="h-20"
                                      EmptyCellColor="bg-[#DBD9C0]"
                                      FilledCellColor="bg-[#C0FF06]"
                                      FilledCellHeight={parseInt(
                                        i?.yourCandidatePoolAverage
                                      )}
                                      tooltip1Text="Average"
                                      tooltip3Text={`${Number(
                                        i?.yourCandidatePoolAverage || 0
                                      ).toFixed(0)}%`}
                                      paddingTooltip="py-2"
                                      marginTopTooltip="-mt-[70px]"
                                      widthtooltip="w-[4.6rem] 2xl:w-[6rem]"
                                    />
                                  )}
                                  {selectedGraphFilter ===
                                    "Dexta candidate pool average" && (
                                    <VerticalBar
                                      filter={selectedGraphFilter}
                                      heightOfCell="h-20"
                                      EmptyCellColor="bg-[#DBD9C0]"
                                      FilledCellColor="bg-[#252E3A]"
                                      FilledCellHeight={parseInt(
                                        i?.averageScore
                                      )}
                                      tooltip1Text="Dexta average"
                                      tooltip3Text={`${Number(
                                        i?.averageScore || 0
                                      ).toFixed(0)}%`}
                                      paddingTooltip="py-2"
                                      marginTopTooltip="-mt-[70px]"
                                      widthtooltip="w-[4.6rem] 2xl:w-[6rem]"
                                    />
                                  )}
                                  {selectedGraphFilter ===
                                    "Dexta best performing candidate" && (
                                    <VerticalBar
                                      filter={selectedGraphFilter}
                                      heightOfCell="h-20"
                                      EmptyCellColor="bg-[#DBD9C0]"
                                      FilledCellColor="bg-[#A16207]"
                                      FilledCellHeight={
                                        parseInt(i?.averageScore) + 6
                                      }
                                      tooltip1Text="Dexta Best"
                                      tooltip3Text={
                                        parseInt(i?.manualDextaBest)
                                          ? parseInt(i?.manualDextaBest) + "%"
                                          : parseInt(i?.averageScore) + 7 + "%"
                                      }
                                      paddingTooltip="py-2"
                                      marginTopTooltip="-mt-[70px]"
                                      widthtooltip="w-[4.6rem] 2xl:w-[6rem]"
                                    />
                                  )}
                                </>
                              )}
                            {i?.experience === "" &&
                              (selectedGraphFilter ===
                                "Dexta candidate pool average" ||
                                selectedGraphFilter ===
                                  "Dexta best performing candidate") && (
                                <VerticalBar
                                  heightOfCell="h-20"
                                  EmptyCellColor="bg-[#DBD9C0]"
                                  FilledCellColor={
                                    selectedGraphFilter ===
                                    "Dexta candidate pool average"
                                      ? "bg-[#252E3A]"
                                      : "bg-[#A16207]"
                                  }
                                  FilledCellHeight={0}
                                  tooltip1Text="Dexta comparison"
                                  tooltip2Text="unavailable"
                                  paddingTooltip="py-2"
                                  marginTopTooltip="-mt-[70px]"
                                  widthtooltip="w-[4.6rem] 2xl:w-[6rem]"
                                />
                              )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <p
                    className="text-coalColor px-5 py-4 text-base 2xl:text-lg  mt-4 w-full"
                    style={{ fontFamily: "Archia Semibold" }}
                  >
                    Candidate Pool Comparison{" "}
                  </p>
                  <div className="px-5">
                    {scoresArray.length > 4 ? (
                      <>
                        <div className="relative">
                          <div className="mt-2 px-3 py-2 bg-[#DBD9C0] rounded-lg">
                            {isMobile ? (
                              <>
                                <p style={{ fontFamily: "Silka" }}>
                                  Would you like to view Score Distribution
                                  Analysis?
                                </p>
                                <div className="mt-5">
                                  <CustomButton
                                    label="Click here"
                                    textSize="font-medium"
                                    hoverBgColor="#252E3A"
                                    hoverTextColor="#FFFFFF"
                                    bgColor="#C0FF06"
                                    widthButton="w-full"
                                    borderCustom="border border-coalColor text-coalColor"
                                    onClickButton={() => {
                                      dispatch(
                                        setGraphData({
                                          xArray: scoresArray,
                                          myScore:
                                            performanceData?.candidateScore,
                                          firstName:
                                            data?.candidate?.candidates
                                              ?.firstName,
                                          lastName:
                                            data?.candidate?.candidates
                                              ?.lastName,
                                        })
                                      );
                                      navigate("/score-distribution-graph");
                                    }}
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <BoxPlotChart
                                  xArray={scoresArray}
                                  myScore={performanceData?.candidateScore}
                                  firstName={
                                    data?.candidate?.candidates?.firstName
                                  }
                                  lastName={
                                    data?.candidate?.candidates?.lastName
                                  }
                                  avgScore={Number(
                                    data?.averageScoreOfAllCandidates || 0
                                  ).toFixed(0)}
                                />
                              </>
                            )}
                          </div>
                        </div>
                        <div className="relative">
                          <div className="mt-10 px-3 py-2 bg-[#DBD9C0] rounded-lg">
                            {isMobile ? (
                              <>
                                <p style={{ fontFamily: "Silka" }}>
                                  Would you like to view Completion Time
                                  Analysis?
                                </p>
                                <div className="mt-5">
                                  <CustomButton
                                    label="Click here"
                                    textSize="font-medium"
                                    hoverBgColor="#252E3A"
                                    hoverTextColor="#FFFFFF"
                                    bgColor="#C0FF06"
                                    widthButton="w-full"
                                    borderCustom="border border-coalColor text-coalColor"
                                    onClickButton={() => {
                                      dispatch(
                                        setGraphData({
                                          xArray: timesArray,
                                          myScore: Number(
                                            completionData?.candidateCompletionTime ||
                                              0
                                          ).toFixed(0),
                                          firstName:
                                            data?.candidate?.candidates
                                              ?.firstName,

                                          lastName:
                                            data?.candidate?.candidates
                                              ?.lastName,
                                        })
                                      );
                                      navigate("/completion-time-graph");
                                    }}
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <BoxPlotChart2
                                  xArray={timesArray}
                                  myScore={Number(
                                    completionData?.candidateCompletionTime || 0
                                  ).toFixed(0)}
                                  firstName={
                                    data?.candidate?.candidates?.firstName
                                  }
                                  lastName={
                                    data?.candidate?.candidates?.lastName
                                  }
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="relative">
                        <div className="relative">
                          <div className="mt-2 px-3 py-2 bg-[#DBD9C0] rounded-lg">
                            {isMobile ? (
                              <>
                                <p style={{ fontFamily: "Silka" }}>
                                  Would you like to view Score Distribution
                                  Analysis?
                                </p>
                                <div className="mt-5">
                                  <CustomButton
                                    label="Click here"
                                    textSize="font-medium"
                                    hoverBgColor="#252E3A"
                                    hoverTextColor="#FFFFFF"
                                    bgColor="#C0FF06"
                                    widthButton="w-full"
                                    borderCustom="border border-coalColor text-coalColor"
                                    onClickButton={() => {
                                      dispatch(
                                        setGraphData({
                                          xArray: scoresArray,
                                          myScore:
                                            performanceData?.candidateScore,
                                          firstName:
                                            data?.candidate?.candidates
                                              ?.firstName,
                                          lastName:
                                            data?.candidate?.candidates
                                              ?.lastName,
                                        })
                                      );
                                      navigate("/score-distribution-graph");
                                    }}
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <BoxPlotChart
                                  xArray={scoresArray_dummy}
                                  myScore={20}
                                  firstName={
                                    data?.candidate?.candidates?.firstName
                                  }
                                  lastName={
                                    data?.candidate?.candidates?.lastName
                                  }
                                  avgScore={50}
                                />
                              </>
                            )}
                          </div>
                        </div>
                        <div className="relative">
                          <div className="mt-10 px-3 py-2 bg-[#DBD9C0] rounded-lg">
                            <BoxPlotChart2
                              xArray={timesArray_dummy}
                              myScore={50}
                              firstName={data?.candidate?.candidates?.firstName}
                              lastName={data?.candidate?.candidates?.lastName}
                            />
                          </div>
                        </div>
                        <div class="absolute inset-0 flex justify-center items-center z-20 backdrop-blur-lg">
                          <div class="p-8 bg-coalColor h-40 w-5/6 rounded-lg flex flex-col justify-center items-center">
                            <img src={Lock} class="w-8 h-9" />
                            <p
                              class="text-center text-white mt-2"
                              style={{ fontFamily: "Silka Light" }}
                            >
                              Insufficient data available for analysis. At least
                              5 candidates need to complete the test before
                              graphs can be visualized
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        )}
      </div>
      {deleteModal && (
        <DeleteModal
          setDeleteModal={setDeleteModal}
          onClick={handleDeleteUser}
          header="Delete your candidate"
          icon={DeleteIcon}
          loading={deleteUserLoading}
          buttonText="Delete candidate"
          description=" Are you sure you want to delete your candidate? This action cannot be undone."
        />
      )}
      {confirmModal && (
        <ConfirmModal
          setConfirmModal={setConfirmModal}
          onClick={send_email}
          icon={confirm}
          header="Send results to candidate"
          loading={deleteUserLoading}
          buttonText="Yes"
          description=" Are you sure you want to send results to candidate"
        />
      )}
      {helpModal && (
        <InterpretResults setHelp={setHelp} helpModal={helpModal} />
      )}
    </>
  );
};

export default Details_Assessment;
