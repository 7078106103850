import http from "../../../../../http";

export const getAssessmentDetails = async (id, userID) => {
  const response = await http.get(
    `/assessment/candidate/result/assessment/${id}/user/${userID}`
  );
  const {
    webcamOpenCount,
    webcamCloseCount,
    fullscreenOpenCount,
    fullscreenCloseCount,
    mouseInAssessmentWindowOpenCount,
    mouseInAssessmentWindowCloseCount,
    TotalCount,
  } = response?.data?.evaloation_Response || {};

  const parse = (value) => parseInt(value || 0, 10);

  const webopen = parse(webcamOpenCount);
  const webclose = parse(webcamCloseCount);
  const webCamtotal = parse(webcamCloseCount) + parse(webcamOpenCount);

  const webcamPercentage = ((webopen / webCamtotal) * 100).toFixed(2);

  let webcamStatus = "Not always";
  if (webcamPercentage >= 90) {
    webcamStatus = "Yes";
  } else if (webopen === 0 || webopen === 1) {
    webcamStatus = "No";
  }

  response.data["Webcam"] = webcamStatus;
  response.data["full"] = parseInt(fullscreenCloseCount) > 0 ? false : true;
  response.data["mouse"] =
    parseInt(mouseInAssessmentWindowCloseCount) > 0 ? false : true;
  response.data["LogsLoad"] = false;
  return response.data;
};
