import React, { useEffect, useState } from "react";
import "./Auth.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { forgotEmail } from "./hooks/forgotEmail";
import { toast } from "react-toastify";
import { ToastContainer, Zoom } from "react-toastify";
import queryString from "query-string";
import MainLogo from "../../Dexta_assets/LoginLogov4.png";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { FaArrowRightLong } from "react-icons/fa6";

const ForgetPasswordEmailSent = () => {
  const location = useLocation();
  const parseOptions = { decode: false };
  const parsed = queryString.parse(location.search, parseOptions);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [toastError, setToasterror] = useState("");
  const [toastSuccess, setToastSuccess] = useState(false);

  //#region api call for forget passwowrd
  const { mutate: mutateEmail, isLoading: emailLoading } = useMutation(
    forgotEmail,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries("/auth/forgot-password");
        if (response.message === "Reset password email sent")
          setToastSuccess("Email Sent");
      },
      onError: (error) => {
        if (error.response.data.message[0] != "")
          setToasterror("Invalid Email");
      },
    }
  );
  //#endregion

  //#region success and error toasts
  useEffect(() => {
    if (toastSuccess != "") {
      toast.success(toastSuccess, {
        toastId: "copy-success",
      });
    }
    setTimeout(() => {
      setToastSuccess("");
    }, 1000);
  }, [toastSuccess]);

  useEffect(() => {
    if (toastError != "") {
      toast.success(toastError, {
        toastId: "copy-success",
      });
    }
    setTimeout(() => {
      setToasterror("");
    }, 1000);
  }, [toastError]);
  //#endregion

  //#region Submitting Email
  const handleEmailSubmit = () => {
    let data = JSON.stringify({
      email: parsed?.email,
    });
    try {
      mutateEmail(data);
    } catch (error) {}
  };
  //#endregion

  //#region opening emailbox
  const handleOpenEmailInboxButtonClick = () => {
    // Constructing the mailto link without any additional parameters
    const mailtoLink = "mailto:";

    // Opening the default email client
    window.location.href = mailtoLink;
  };

  //#endregion

  document.title = "Forget Password | Dexta";

  return (
    <React.Fragment>
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="sm:grid-cols-1 lg:grid-cols-2 grid bg-bgAuth h-screen">
        <div className="bg-black sm:hidden lg:block">
          <img
            src={MainLogo}
            className="w-full lg:h-full xl:h-fit lg:object-cover"
          />
        </div>
        <div className="bg-bgAuth flex justify-center flex-col pb-40">
          <div className="sm:w-3/4 lg:w-1/2 mx-auto">
            <h1
              className="text-2xl text-left"
              style={{ fontFamily: "Archia Semibold" }}
            >
              {" "}
              Check your email{" "}
            </h1>
            <div
              style={{ fontFamily: "Silka" }}
              className="text-sm mt-6 flex flex-col"
            >
              <span>We sent a password reset link to</span>
              <span>{parsed?.email}</span>
            </div>
            <div className="mt-8">
              <CustomButton
                label="OPEN EMAIL OPEN"
                borderCustom="border border-black text-white"
                paddingY="0.7rem"
                bgColor="#252E3A"
                hoverBgColor="#C0FF06"
                hoverTextColor="#252E3A"
                iconR={FaArrowRightLong}
                noMarginIcon={false}
                autoLeftMargin="ml-auto"
                textMarginBotton="ml-auto"
                onClickButton={handleOpenEmailInboxButtonClick}
              />
            </div>
            <p
              className=" text-sm my-auto mt-5"
              style={{ fontFamily: "Silka" }}
            >
              Didn’t receive the email?{" "}
              <span
                className="text-red-500 cursor-pointer underline"
                onClick={handleEmailSubmit}
              >
                Click to resend
              </span>
            </p>
            <p
              className="underline text-sm my-auto mt-8 cursor-pointer"
              style={{ fontFamily: "Silka" }}
              onClick={() => navigate("/login")}
            >
              Return to login
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ForgetPasswordEmailSent;
