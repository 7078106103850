import React, { useState } from "react";
import Loader from "react-loader-spinner";

const CustomButton = ({
  label,
  paddingY = null,
  borderCustom = null,
  bgColor = null,
  textColor = null,
  hoverBgColor = null,
  hoverTextColor = null,
  icon: Icon,
  iconR: IconR,
  onClickButton,
  disableField,
  disabledTextColor = null,
  Isrelative = null,
  tooltipTitle = null,
  disabledCheck = null,
  disabledColor,
  LoadingBtn = null,
  loadingText = null,
  iconWidth = null,
  iconHeight = null,
  noMarginIcon = null,
  textSize = null,
  textMarginBotton = null,
  widthButton = null,
  marginTop = null,
  autoLeftMargin = null,
  loadingColor = null,
  paddingx = null,
  justifyContent = null,
  id,
  imageLeft = null,
  buttonType = null,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const dynamicStyles = {
    paddingY: paddingY || "0.5rem",
    bgColor: bgColor || "#FFFFFF",
    color: textColor || "#000000",
    hoverBgColor: hoverBgColor || bgColor,
    hoverTextColor: hoverTextColor || textColor,
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <button
      id={id}
      className={`inline-flex items-center 
                ${marginTop && marginTop}
                ${Isrelative ? "relative" : null}
                ${!disabledCheck && borderCustom && borderCustom} 
                ${justifyContent ? justifyContent : "justify-center"} ${
        paddingx ? paddingx : "px-5"
      } leading-[2] mb-1 
                ${textSize ? textSize : "text-sm"} 
                ${widthButton ? widthButton : "w-full"} 
                font-medium rounded`}
      style={{
        backgroundColor: disabledCheck
          ? disabledColor
          : isHovered && !LoadingBtn
          ? dynamicStyles.hoverBgColor
          : dynamicStyles.bgColor,
        color: disabledCheck
          ? disabledTextColor
          : isHovered && !LoadingBtn
          ? dynamicStyles.hoverTextColor
          : dynamicStyles.textColor,
        paddingTop: dynamicStyles.paddingY,
        paddingBottom: dynamicStyles.paddingY,
      }}
      type={buttonType ? buttonType : "submit"}
      onClick={onClickButton}
      disabled={disableField}
      title={tooltipTitle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {LoadingBtn ? (
        <span className="flex items-center justify-center font-bold">
          <Loader
            type="Oval"
            color={loadingColor ? loadingColor : "white"}
            height={20}
            width={20}
          />
          <span className={`${loadingText && "ml-2"}`}>{loadingText}</span>
        </span>
      ) : (
        <>
          {imageLeft && (
            <img
              src={imageLeft}
              className={`my-auto ${iconWidth ? `w-${iconWidth}` : "w-5"} ${
                iconHeight ? `h-${iconHeight}` : "w-5"
              } ${noMarginIcon ? "mr-0" : "ml-2"} ${
                autoLeftMargin && autoLeftMargin
              } `}
            />
          )}
          {Icon && (
            <Icon
              className={`my-auto ${iconWidth ? `w-${iconWidth}` : "w-5"} ${
                iconHeight ? `h-${iconHeight}` : "w-5"
              } ${noMarginIcon ? "mr-0" : "mr-2"}`}
            />
          )}
          <span className={`${textMarginBotton && textMarginBotton}`}>
            {label}
          </span>
          {IconR && (
            <IconR
              className={`my-auto ${iconWidth ? `w-${iconWidth}` : "w-5"} ${
                iconHeight ? `h-${iconHeight}` : "w-5"
              } ${noMarginIcon ? "mr-0" : "ml-2"} ${
                autoLeftMargin && autoLeftMargin
              } `}
            />
          )}
        </>
      )}
    </button>
  );
};

export default CustomButton;
