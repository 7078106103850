import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import closeIcon from "../../../../../Dexta_assets/closeModal.png";
import { ToastContainer, toast, Zoom } from "react-toastify";
import CustomButton from "../../../../../Components/CustomButton/CustomButton";
import { updateHiringStatus } from "../hooks/updateHiringStatus";
import queryString from "query-string";
import { useLocation, useParams } from "react-router-dom";
import CustomizeComponent from "../../../../../Components/CustomizeEmail/CustomizeComponent";
import { useSelector } from "react-redux";
import King from "../../../../../Assets/preee.png";
import Premium from "../../../../../Components/Modals/Premium";

export default function PassCandidate({
  passOpen,
  setPassOpen,
  firstName,
  lastName,
}) {
  const cancelButtonRef = useRef(null);
  const userID = localStorage.getItem("CP-USER-ID");
  const [error_, setError] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const queryClient = useQueryClient();
  const { id } = useParams();
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const [customizeCustomModal, setCustomizeCustomModal] = useState(false);
  const [emailContent, setEmailContent] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailType, setEmailType] = useState("");
  const user_package_check = useSelector(
    (state) => state.packageDetails.setPackage
  );
  const [premiumOpen, setPremiumOpen] = useState(false);

  //#region updating hiring status
  const { mutate: hiringMutate, isLoading: hiringLoading } = useMutation(
    updateHiringStatus,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries("/assessment/candidate");
        toast.success("Candidate passed", {
          toastId: "copy-success",
        });
        setPassOpen(false);
      },
      onError: () => {},
    }
  );

  const handlehiringChange = () => {
    let data = {
      IDS: {
        userID: parsed.user_ID,
        Assessment: id,
      },
      postData: JSON.stringify({
        sendApprovalEmail: true,
        status: "PASSED",
      }),
    };
    try {
      hiringMutate(data);
    } catch (err) {
      //react-query will handle error
    }
  };
  //#endregion

  //#region opening rejection template
  const handleRejectEmailTemplate = () => {
    setEmailType("APPROVAL");
    setCustomizeCustomModal(true);
  };
  //#endregion

  return (
    <Dialog
      open={passOpen}
      onClose={() => setPassOpen(false)}
      className="fixed inset-0 z-40 flex items-center justify-center overflow-y-auto"
    >
      <CustomizeComponent
        customizeCustomModal={customizeCustomModal}
        setCustomizeCustomModal={setCustomizeCustomModal}
        emailContent={emailContent}
        emailSubject={emailSubject}
        emailType={emailType}
        passCheck={true}
        setPassOpen={setPassOpen}
        firstName={firstName}
        lastName={lastName}
      />
      <Premium premiumOpen={premiumOpen} setPremiumOpen={setPremiumOpen} />
      <div className="fixed inset-0 bg-black bg-opacity-75" />
      <Dialog.Panel
        className="relative bg-white rounded-lg overflow-hidden shadow-lg transform transition-all sm:max-w-md sm:w-full"
        style={{ height: "250px" }}
      >
        <div className="bg-white">
          <p
            className="text-lg font-medium text-coalColor pl-5 cursor-pointer hover:text-coalColor text-left pt-5"
            style={{ fontFamily: "Archia Bold" }}
          >
            Pass candidate
          </p>
          <img
            src={closeIcon}
            className="absolute top-3 right-5 z-20 w-6 h-6 cursor-pointer"
            onClick={() => setPassOpen(false)}
          />
          <p className="pl-5 mt-5" style={{ fontFamily: "Silka" }}>
            Do you want to pass this candidate?
          </p>
          <div className="ml-6 mt-5">
            <div className="flex flex-row">
              <label
                className="relative my-auto flex cursor-pointer items-center rounded-full"
                data-ripple-dark="true"
              >
                <input
                  id="ripple-on"
                  type="checkbox"
                  checked={isChecked}
                  onChange={() => setIsChecked(!isChecked)}
                  className="peer relative h-4 w-4 cursor-pointer checked:bg-primaryGreen appearance-none rounded border-[1px] transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-8 before:w-8 border-gray-700 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-coalColor hover:before:opacity-50"
                />
                <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-coalColor opacity-0 transition-opacity peer-checked:opacity-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3.5 w-3.5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeWidth="1"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
              </label>
              <p
                className="ml-2 text-sm my-auto"
                style={{ fontFamily: "Silka Light" }}
              >
                Send a pass email to this candidate as well
              </p>
            </div>
            <p className="flex flex-row mt-3" style={{ fontFamily: "Silka" }}>
              {user_package_check !== "Enterprise" && (
                <img src={King} className="w-4 h-4 my-auto mr-2" />
              )}
              You can customise the pass email{" "}
              <p
                className="text-black font-bold ml-1 underline"
                style={{ fontFamily: "Archia Bold" }}
                onClick={() => {
                  if (user_package_check !== "Enterprise") {
                    setPremiumOpen(true);
                  } else {
                    handleRejectEmailTemplate();
                  }
                }}
              >
                here
              </p>
              .
            </p>
            <div className="flex flex-row justify-end px-8 gap-5 mt-8">
              <CustomButton
                label="Cancel"
                textSize="text-base"
                borderCustom="border border-coalColor text-white"
                bgColor="#252E3A"
                hoverBgColor="#C0FF06"
                hoverTextColor="#252E3A"
                widthButton="w-[5rem]"
                paddingY="0.3rem"
                textColor="black"
                onClickButton={() => setPassOpen(false)}
              />
              <CustomButton
                label="Pass"
                textSize="text-base"
                bgColor="#C0FF06"
                widthButton="w-[5rem]"
                textColor="black"
                borderCustom="border border-black text-coalColor"
                hoverBgColor="#252E3A"
                hoverTextColor="white"
                paddingY="0.3rem"
                disabledColor="#D3D5D8"
                disabledTextColor="#7C8289"
                disabledCheck={!isChecked}
                disableField={!isChecked}
                LoadingBtn={hiringLoading ? true : false}
                loadingText="Passing"
                onClickButton={() => {
                  handlehiringChange();
                }}
              />
            </div>
          </div>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
}
