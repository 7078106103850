import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import "./Assessments.css";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { updateStep } from "./hooks/updateStep";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { ToastContainer, toast, Zoom } from "react-toastify";
import Loader from "react-loader-spinner";
import { getAssessmentByID } from "./hooks/getAssessmentByID";
import { useSelector, useDispatch } from "react-redux";
import { setNextCandidateToFalse } from "../../../../redux/reducers/NextCandidate/NextCandidateSlice";
import {
  setCopytoTrue,
  setCopytoFalse,
} from "../../../../redux/reducers/CopyLink/CopySlice";
import { invite_tabs } from "../../../../Components/InviteCandidates/data";
import InviteByEmail from "../../../../Components/InviteCandidates/InviteByEmail";
import PremiumModaloverModal from "../../../../Components/Modals/PremiumModaloverModal";
import CustomButton from "../../../../Components/CustomButton/CustomButton";
import { setClearToTrue } from "../../../../redux/reducers/ClearRows/ClearRowsSlice";
import CustomizeEmail from "../../../../Components/CustomizeEmail/CustomizeEmail";
import { getSelectedModules } from "./hooks/getSelectedModulesByID";
import axios from "axios";
import InviteByBulk from "../../../../Components/InviteCandidates/InviteByBulk";
import King from "../../../../Assets/preee.png";
import { getAssessmentUniqueCode } from "./hooks/getAssessmentUniqueCode";
import Alertinfo from "../../../../Dexta_assets/alertinfo.png";
const Candidates = (props) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [input, setInput] = useState("");
  const [tags, setTags] = useState([]);
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const [lengthTags, setlength] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const [data_assessment, setDataAssessment] = useState(null);
  const assessment_id = localStorage.getItem("assessment_ID");
  const next = useSelector((state) => state.nextCandidate.setNextCandidate);
  const [selectedTab, setSelectedTab] = useState("Invite by email");
  const [inviteData, setInviteData] = useState([]);
  const [premiumGeneralOpen, setPremiumGeneral] = useState(false);
  const [customizeModal, setCustomizeModal] = useState(false);
  const user_package_check = useSelector(
    (state) => state.packageDetails.setPackage
  );
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [barLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("CP-USER-TOKEN");
  const [sectionsData, setSectionsData] = useState(null);
  const [assessmentCode, setAssessmentCode] = useState(null);
  const [copyCount, setCopyCount] = useState(0);
  //#region Handle item from tab
  const handleTabClick = (name) => {
    setSelectedTab(name);
  };
  //#endregion

  useEffect(() => {
    if (next) {
      handleSubmit();
      if (props?.data?.fileBulk !== null) {
        handleBulkSubmit();
      }
    }
  }, [next]);

  useEffect(() => {
    if (file !== null) {
      props.setData({ ...props.data, fileBulk: file });
    }
  }, [file]);

  useEffect(() => {
    setInviteData(props?.data?.tags);
  }, []);

  //#region take user to top of screen
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //#endregion

  //#region Get assessment data
  useEffect(() => {
    if (parsed && parsed.assessment_id !== undefined) {
      setIsLoading(true);
      getAssessmentByID(parsed.assessment_id)
        .then((result) => {
          setDataAssessment(result);
          setIsLoading(false);
        })
        .catch((error) => {
          setError(error);
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);
      getAssessmentByID(parseInt(assessment_id))
        .then((result) => {
          setDataAssessment(result);
          setIsLoading(false);
        })
        .catch((error) => {
          setError(error);
          setIsLoading(false);
        });
    }
  }, [parsed.assessment_id]);
  //#endregion

  //#region copying link to clipboard
  const copyToClipboard = (code) => {
    try {
      // Dismiss all existing toasts first
      toast.dismiss();

      setCopyCount(copyCount + 1);
      let url = process.env.REACT_APP_STATIC_SITE + "/confirmation/" + code;
      const textArea = document.createElement("textarea");
      const codeToCopy = url.toString();
      textArea.value = codeToCopy;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      toast.success("The invitation link has been copied.", {
        toastId: "copy-success",
      });
      dispatch(setCopytoTrue(true));
    } catch (error) {
      console.error(error);
      toast.error("Copy failed", {
        toastId: "copy-error",
      });
    }
  };
  //#endregion

  //#region clearning invites list
  const handleClear = () => {
    dispatch(setClearToTrue(true));
  };
  //#endregion

  //#region Fetch Selected Modules
  const {
    data: modulesData,
    error: modulesError,
    isLoading: modulesLoading,
  } = useQuery(
    [
      "sections",
      parsed && parsed.assessment_id !== undefined
        ? parsed.assessment_id
        : assessment_id,
    ],
    () =>
      getSelectedModules(
        parsed && parsed.assessment_id !== undefined
          ? parsed.assessment_id
          : assessment_id
      )
  );
  //#endregion

  //#region handle invites submit
  const handleSubmit = () => {
    let data = {
      content: {
        candidates: inviteData,
        status: "active",
        notes: "complete",
        setpThree: "completed",
        isFinished: true,
      },
      categoryID:
        parsed && parsed.assessment_id !== undefined
          ? parsed.assessment_id
          : assessment_id,
    };

    try {
      mutate(data);
    } catch (err) {
      //
    }
  };

  const handleBulkSubmit = async () => {
    setIsLoading(true);
    if (!file) {
      setError("Please select a file.");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios
        .post(
          `${process.env.REACT_APP_Server}/assessment/${
            parsed?.assessment_id && parsed?.assessment_id != null
              ? parsed.assessment_id
              : parseInt(assessment_id)
          }/bulk-invite/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              );
              setProgress(percentCompleted);
            },
          }
        )
        .then((res) => {
          toast.success("Invitations sent", {
            toastId: "copy-success",
          });
          handleSubmit();
        });
      if (!response.ok) {
        throw new Error("Failed to upload file");
      }
    } catch (error) {
      setError("Failed to upload file");
      toast.error(error?.response?.data?.message, {
        toastId: "copy-success",
        style: { width: "400px" },
      });
    } finally {
      setIsLoading(false);
      setProgress(0);
      setFile(null);
      queryClient.invalidateQueries("assessment");
      // toast.success("Invitations sent")
    }
  };

  const { mutate, isLoading: mutateLoading } = useMutation(updateStep, {
    onSuccess: () => {
      queryClient.invalidateQueries("assessment");
      toast.success("Invite has been sent", {
        toastId: "copy-success",
      });
      dispatch(setClearToTrue(true));
      props.setselecteditem("finished");
      localStorage.setItem("current_module", "finished");
      dispatch(setNextCandidateToFalse(false));
      props.setCandidatesLoading(false);
      dispatch(setCopytoFalse(false));
    },
    onError: (error) => {
      dispatch(setNextCandidateToFalse(false));
      props.setCandidatesLoading(false);
      const errorResponseMessage = error?.response?.data?.message;
      const firstErrorMessage = Array.isArray(errorResponseMessage)
        ? errorResponseMessage[0]
        : errorResponseMessage;

      if (firstErrorMessage) {
        if (firstErrorMessage.includes(".com")) {
          toast.error(
            `This candidate ${error.response.data.message[0]} has already been invited`,
            {
              style: { width: "450px" },
            }
          );
        }
        if (firstErrorMessage.includes("active")) {
          // setTimeout(() => {
          //   toast.error(firstErrorMessage, {
          //     style: { width: "450px" },
          //   });
          // }, 500);
          navigate("/dashboard");
        } else {
          setTimeout(() => {
            toast.error(firstErrorMessage, {
              toastId: "copy-success",
              style: { width: "450px" },
            });
          }, 500);
        }
      } else {
        toast.error("An error occurred.", {
          toastId: "copy-success",
        });
      }

      setError(true);
      setErrorMessage(firstErrorMessage || "An error occurred.");
    },
  });

  const { mutate: linkMutate, isLoading: linkLoading } = useMutation(
    updateStep,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("assessment");
      },
      onError: (error) => {
        const errorResponseMessage = error?.response?.data?.message;
        const firstErrorMessage = Array.isArray(errorResponseMessage)
          ? errorResponseMessage[0]
          : errorResponseMessage;

        if (firstErrorMessage) {
          if (firstErrorMessage.includes(".com")) {
            toast.error(
              `This candidate ${error.response.data.message[0]} has already been invited`,
              {
                toastId: "copy-success",
                style: { width: "450px" },
              }
            );
          }
          if (firstErrorMessage.includes("active")) {
            navigate("/dashboard");
          } else {
            setTimeout(() => {
              toast.error(firstErrorMessage, {
                toastId: "copy-success",
                style: { width: "450px" },
              });
            }, 500);
          }
        } else {
          toast.error("An error occurred.", {
            toastId: "copy-success",
          });
        }

        setError(true);
        setErrorMessage(firstErrorMessage || "An error occurred.");
      },
    }
  );

  // useEffect(() => {
  //   setError(error?.response?.data?.message[0] ? true : false);
  // });
  //#endregion

  useEffect(() => {
    props.setData({ ...props.data, tags: inviteData });
  }, [inviteData]);

  //#region Link copied and marked step in api as completed
  const HandleLinkCopied = () => {
    let data = {
      content: {
        status: "active",
        notes: "complete",
        setpThree: "completed",
        isFinished: true,
      },
      categoryID:
        parsed && parsed.assessment_id !== undefined
          ? parsed.assessment_id
          : assessment_id,
    };
    try {
      linkMutate(data);
    } catch (err) {
      //
    }
  };
  //#endregion

  //#region get assessments code
  const { mutate: uniqueMutate } = useMutation(getAssessmentUniqueCode, {
    onSuccess: (res) => {
      queryClient.invalidateQueries("/assessment/invite");
      setAssessmentCode(res?.attemptCode);
      console.log(res, "response");
    },
  });

  const handleAssessmentCode = () => {
    let data = {
      assessmentId:
        parsed?.assessment_id && parsed?.assessment_id != null
          ? parseInt(parsed.assessment_id)
          : parseInt(assessment_id),
    };
    try {
      uniqueMutate(data);
    } catch (err) {
      //
    }
  };

  useEffect(() => {
    handleAssessmentCode();
  }, [copyCount]);
  //#endregion

  return (
    <React.Fragment>
      <ToastContainer
        position="top-center"
        transition={Zoom}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        enableMultiContainer={false}
        limit={1}
      />
      <div className="sm:px-2 pb-20">
        <div className="bg-white rounded-lg">
          <div className="p-5 pt-5 md:px-10">
            <h2
              className="text-coalColor mt-3"
              style={{ fontFamily: "Archia Semibold" }}
            >
              Select candidates
            </h2>
            <p className="text-coalColor mt-8" style={{ fontFamily: "Silka" }}>
              Share invite link:
            </p>
            <div className="py-3 px-3 mt-3 bg-[#DBD9C0] rounded-lg flex flex-row gap-3">
              <img src={Alertinfo} className="w-5 h-5 my-auto" />
              <p style={{ fontFamily: "Silka" }}>
                Share a unique link with each candidate to track their test
                completion progress. Remember to regenerate the link for each
                new candidate
              </p>
            </div>
            <div
              className="flex sm:flex-col lg:flex-row mt-5 gap-5"
              style={{ fontFamily: "Silka" }}
            >
              {/* Input Container */}
              <div className="relative w-full lg:text-base sm:text-sm border border-1 border-[#D3D5D8] rounded-lg bg-[#F6F7F7]">
                {/* Input for large screens */}
                <input
                  type="text"
                  className="w-full p-4 rounded-lg bg-[#F6F7F7] lg:block sm:hidden outline-none"
                  placeholder={
                    process.env.REACT_APP_STATIC_SITE +
                    "/confirmation/" +
                    assessmentCode
                  }
                  disabled
                />
                {/* Input for medium screens */}
                <input
                  type="text"
                  className="w-full p-4 rounded-lg bg-[#F6F7F7] lg:hidden md:block sm:hidden outline-none"
                  placeholder={process.env.REACT_APP_STATIC_SITE + "/..."}
                  disabled
                />
                {/* Input for small screens */}
                <input
                  type="text"
                  className="w-full p-4 rounded-lg bg-[#F6F7F7] sm:block md:hidden outline-none"
                  placeholder={
                    process.env.REACT_APP_STATIC_SITE +
                    "/confirmation/" +
                    assessmentCode?.split("-")[0]
                  }
                  disabled
                />
              </div>

              {/* Button Container */}
              <div className="flex items-center sm:w-full lg:w-auto">
                <div className="flex justify-center sm:w-auto lg:w-auto">
                  <div className="inline-flex relative sm:w-auto lg:w-auto">
                    <button
                      className="text-white lg:text-base sm:text-sm py-4 px-6 border border-1 border-coalColor bg-coalColor text-center rounded-lg justify-center hover:text-coalColor hover:bg-primaryGreen whitespace-nowrap"
                      onClick={() => {
                        copyToClipboard(assessmentCode);
                        HandleLinkCopied();
                      }}
                      style={{ fontFamily: "Silka" }}
                    >
                      Regenerate and copy
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-row mt-8 gap-4">
              <p className="text-coalColor" style={{ fontFamily: "Silka" }}>
                OR
              </p>
              <hr className="w-full bg-[#D3D5D8] border-1 my-auto" />
            </div>
            <div className="mt-8">
              <div className="flex sm:flex-col lg:flex-row md:justify-between mt-5 mb-7">
                <div className="border-b border-gray-300 w-full">
                  <div className="text-lg font-medium text-center text-gray-500 dark:text-gray-400">
                    <ul className="flex flex-wrap -mb-px ">
                      {invite_tabs
                        ?.filter((i) => i?.title !== "Share link")
                        .map((i, index) => (
                          <li
                            key={index}
                            className="mr-2 cursor-pointer relative"
                            onClick={() => handleTabClick(i.title)}
                          >
                            <a
                              className={`inline-block p-4 ${
                                selectedTab === i.title
                                  ? "text-coalColor border-b-2 border-coalColor rounded-t-lg active"
                                  : "border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300"
                              } `}
                              aria-current="page"
                            >
                              {i.title}
                            </a>
                            {i?.title === "Invite in bulk" &&
                              user_package_check !== "Enterprise" && (
                                <span className="absolute top-2 right-0 -mr-2">
                                  <img
                                    src={King}
                                    alt="Premium Icon"
                                    className="w-5 h-5"
                                  />
                                </span>
                              )}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {selectedTab === "Invite by email" && (
              <React.Fragment>
                <div className="px-3">
                  <p className="text-sm h-5 text-[#000000de]">
                    Invite multiple candidates by entering their first name,
                    last name and email below
                  </p>
                  <InviteByEmail
                    setInviteData={setInviteData}
                    inviteData={inviteData}
                    buttonSize="w-40"
                    trigger={selectedTab}
                    createFlow="yes"
                  />
                </div>
              </React.Fragment>
            )}
            {selectedTab === "Invite in bulk" && (
              <React.Fragment>
                <div className="px-3">
                  <p className="text-sm h-5 text-[#000000de]">
                    Invite multiple candidates at once through a CSV or XLSX
                    file upload.
                  </p>
                  <InviteByBulk
                    setFile={setFile}
                    barLoading={barLoading}
                    progress={progress}
                  />
                </div>
              </React.Fragment>
            )}
            <div className="px-8 flex sm:flex-col md:justify-between mt-12 md:mt-5 md:flex-row w-full">
              <div className="text-[#cb7b7a] my-auto cursor-pointer md:text-left sm:text-center hover:text-[#a14d4b]">
                {selectedTab === "Invite by email" && inviteData.length > 0 && (
                  <span onClick={handleClear}>Clear list</span>
                )}
              </div>
              <div className="flex md:flex-row sm:flex-col items-center">
                {(selectedTab === "Invite by email" ||
                  selectedTab === "Invite in bulk") && (
                  <>
                    <PremiumModaloverModal
                      premiumGeneralOpen={premiumGeneralOpen}
                      setPremiumGeneral={setPremiumGeneral}
                    />
                    <div className="relative">
                      <span
                        className="text-black underline sm:mr-auto md:mr-4 md:w-[15rem] flex flex-row cursor-pointer"
                        onClick={() =>
                          user_package_check === "Enterprise"
                            ? setCustomizeModal(true)
                            : setPremiumGeneral(true)
                        }
                      >
                        {user_package_check !== "Enterprise" && (
                          <span className="mr-2 my-auto">
                            <img
                              src={King}
                              alt="Premium Icon"
                              className="w-4 h-4"
                            />
                          </span>
                        )}
                        Customise invitation email
                      </span>
                    </div>
                    <div className="md:w-[10rem] sm:w-full md:mt-0 sm:mt-4">
                      <CustomButton
                        label="Invite Candidate"
                        bgColor="#C0FF06"
                        borderCustom="border border-coalColor"
                        hoverBgColor="#252E3A"
                        hoverTextColor="#FFFFFF"
                        onClickButton={
                          selectedTab === "Invite by email"
                            ? handleSubmit
                            : handleBulkSubmit
                        }
                        disabledColor="#D3D5D8"
                        LoadingBtn={mutateLoading ? true : false}
                        loadingText="Inviting"
                        loadingColor="black"
                        disableField={
                          (selectedTab === "Invite by email" &&
                            inviteData.length === 0) ||
                          (selectedTab === "Invite in bulk" && file === null)
                        }
                        disabledCheck={
                          (selectedTab === "Invite by email" &&
                            inviteData.length === 0) ||
                          (selectedTab === "Invite in bulk" && file === null)
                        }
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {customizeModal && (
        <CustomizeEmail
          customizeModal={customizeModal}
          setCustomizeModal={setCustomizeModal}
          modulesData={modulesData}
          emailOpacity={true}
          assessmentID={
            parsed && parsed.assessment_id !== undefined
              ? parsed.assessment_id
              : assessment_id
          }
        />
      )}
    </React.Fragment>
  );
};

export default Candidates;
