import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import "react-loading-skeleton/dist/skeleton.css";
import { IoClose } from "react-icons/io5";
import { ToastContainer, Zoom, toast } from "react-toastify";
import CustomButton from "../CustomButton/CustomButton";
import { useParams } from "react-router-dom";
import TextFieldCustom from "../TextField/TextFieldCustom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getEmailContent } from "../hooks/getEmailContent";
import ReactHtmlParser from "react-html-parser";
import { getCompanyDetails } from "../../Pages/Profile/Settings/hooks/getCompanyDetails";
import "react-loading-skeleton/dist/skeleton.css";
import { postEmail } from "../hooks/postEmail";
import { patchEmail } from "../hooks/patchEmail";
import { getAssessmentByID } from "../../Pages/Profile/MyAssessments/Assessments-main/hooks/getAssessmentByID";

const Quill = ReactQuill.Quill;
var Font = Quill.import("formats/font");
Font.whitelist = ["Archia"];
Quill.register(Font, true);

const fontClassToStyleMap = {
  "ql-font-Archia": "font-family: 'Archia';",
};

const fontSizeArr = [
  "8px",
  "9px",
  "10px",
  "12px",
  "14px",
  "16px",
  "20px",
  "24px",
  "32px",
  "42px",
  "54px",
  "68px",
  "84px",
  "98px",
];

var Size = Quill.import("attributors/style/size");
Size.whitelist = fontSizeArr;
Quill.register(Size, true);

export default function CustomizeComponent({
  customizeCustomModal,
  setCustomizeCustomModal,
  emailOpacity = null,
  emailType,
  passCheck = null,
  firstName,
  lastName,
}) {
  const { id } = useParams();
  const cancelButtonRef = useRef(null);
  const queryClient = useQueryClient();
  const userID = localStorage.getItem("CP-USER-ID");
  const [error_, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");

  const [candidate_name, setcandidateName] = useState("");
  const [company_name, setCompany] = useState("");

  //#region Fetching Email Content
  const { data, isLoading, error } = useQuery({
    queryKey: ["email-content", id, userID, emailType],
    queryFn: () => getEmailContent(id, userID, emailType),
  });
  //#endregion

  //#region fetching company data
  const {
    data: userData,
    isLoading: userLoading,
    error: userError,
  } = useQuery({
    queryKey: ["users", id, userID],
    queryFn: () => getCompanyDetails(userID),
  });
  //#endregion

  //#region Fetch GET assessment
  const {
    data: assessmentData,
    error: assessmentError,
    isLoading: assessmentLoading,
  } = useQuery(["assessment".id], () => getAssessmentByID(id));

  //#endregion

  //#region data displaying on editor
  useEffect(() => {
    if (!isLoading && !userLoading && data?.data?.length === 0) {
      if (passCheck) {
        setDescription(`
        <h1><strong style="font-size: 16px;">Dear {candidate_name}</strong></h1>
        <p>
        <span style="font-size: 16px;">We are excited to inform you that after a thorough review and evaluation by the recruitment team at <strong>{company_name}</strong>, you have successfully passed the evaluation process!</span>
        <p><span style="font-size: 16px;">We will soon contact you with more details and the next steps in the hiring process, such as the offer letter and any additional information you may need.</span></p>
        <p><span style="font-size: 16px;">Congratulations once again! We look forward to welcoming you to the team and working together in the near future.</span></p>
        `);
      } else {
        setDescription(`
       <h1><strong style="font-size: 16px;">Dear {candidate_name}</strong></h1>
        <p>
        <span style="font-size: 16px;">Thank you for applying to the <strong>{role_name}</strong> role. We sincerely appreciate your interest in our company, and we are grateful for the time and energy you've invested in our test.</span>
        <p><span style="font-size: 16px;">Unfortunately, after careful consideration, we will not be able to invite you to the next phase of our selection process. We wish you success in your job search and all of your future endeavors.</span></p>
        <p><span style="font-size: 16px;">Once again, we thank you for your interest in working with us.</span></p>
        <p><span style="font-size: 16px;">Kind regards,</span></p>
        <p><strong style="font-size: 16px;">{company_name}</strong></p>
        `);
      }
      setcandidateName(userData?.data[0]?.users[0]?.firstName);
      setSubject(`Your application at ${userData?.data[0]?.companyName}`);
    } else {
      setDescription(data?.data[0]?.body || "");
      setcandidateName(userData?.data[0]?.user?.firstName || "");
      setSubject(data?.data[0]?.subject || "");
    }
  }, [data, isLoading, userData, userLoading, subject]);
  //#endregion

  useEffect(() => {
    // Force update after initial render
    if (!isLoading && !userLoading) {
      setTimeout(() => {
        if (data?.data?.length !== 0) {
          setDescription(data?.data[0]?.body || "");
          setcandidateName(userData?.data[0]?.user?.firstName || "");
          setSubject(data?.data[0]?.subject || "");
        } else {
          if (passCheck) {
            setDescription(`
              <h1><strong>Dear {candidate_name}</strong></h1>
              <p><br /></p>
              <p>We are excited to inform you that after a thorough review and evaluation by the recruitment team at <strong>{company_name}</strong>, you have successfully passed the evaluation process!</p>
              <p><br /></p>
              <p><br /></p>
              <p>We will soon contact you with more details and the next steps in the hiring process, such as the offer letter and any additional information you may need.</p>
              <p><br /></p>
              <p><br /></p>
              <p>Congratulations once again! We look forward to welcoming you to the team and working together in the near future.</p>
            `);
          } else {
            setDescription(`
         <h1><strong style="font-size: 16px;">Dear {candidate_name}</strong></h1>
        <p>
        <span style="font-size: 16px;">Thank you for applying to the <strong>{role_name}</strong> role. We sincerely appreciate your interest in our company, and we are grateful for the time and energy you've invested in our test.</span>
        <p><span style="font-size: 16px;">Unfortunately, after careful consideration, we will not be able to invite you to the next phase of our selection process. We wish you success in your job search and all of your future endeavors.</span></p>
        <p><span style="font-size: 16px;">Once again, we thank you for your interest in working with us.</span></p>
        <p><span style="font-size: 16px;">Kind regards,</span></p>
        <p><strong style="font-size: 16px;">{company_name}</strong></p>
            `);
          }
          setcandidateName(userData?.data[0]?.users[0]?.firstName);
          setSubject(`Your application at ${userData?.data[0]?.companyName}`);
        }
      }, 0);
    }
  }, [data, isLoading, userData, userLoading, customizeCustomModal]);

  //#region Editor options
  const Editor = {};
  Editor.modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, false] }, { font: Font.whitelist }],
      [{ size: Size.whitelist }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [
        {
          color: [
            "#252E3A",
            "#C0FF06",
            "#FF5733",
            "#33FF57",
            "#3357FF",
            "#FF33A8",
            "#A833FF",
            "#33FFA8",
          ],
        },
      ],
    ],
  };

  const replaceFontClassesWithInlineStyles = (html) => {
    // Convert the HTML string into a DOM object
    const domParser = new DOMParser();
    const doc = domParser.parseFromString(html, "text/html");

    // Helper function to add style to an element
    const addStyleToElement = (element, style) => {
      const existingStyle = element.getAttribute("style") || "";
      element.setAttribute("style", `${existingStyle} ${style}`.trim());
    };

    // Iterate over each font class in the map
    Object.keys(fontClassToStyleMap).forEach((fontClass) => {
      const style = fontClassToStyleMap[fontClass];
      // Select all elements with the font class
      const elements = doc.querySelectorAll(`.${fontClass}`);
      elements.forEach((element) => {
        // Add the corresponding style to each element
        addStyleToElement(element, style);
        // Remove the font class from the element
        element.classList.remove(fontClass);
      });
    });

    // Serialize the DOM object back into an HTML string
    return doc.body.innerHTML;
  };
  //#endregion

  //#region post email-content
  const { mutate, isLoading: mutateLoading } = useMutation(postEmail, {
    onSuccess: () => {
      queryClient.invalidateQueries("email-content");
      toast.success("Invitation email created successfully ", {
        toastId: "copy-success",
      });
      setCustomizeCustomModal(false);
    },
  });

  const { mutate: updateMutate, isLoading: updateLoading } = useMutation(
    patchEmail,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("email-content");
        toast.success("Invitation email updated successfully", {
          toastId: "copy-success",
        });
        setCustomizeCustomModal(false);
      },
    }
  );
  //#endregion

  //#region posting template
  const handleTemplateSubmit = () => {
    let data_x = JSON.stringify({
      type: passCheck ? "APPROVAL" : "REJECTION",
      subject: subject,
      body: replaceFontClassesWithInlineStyles(description),
      assessmentId: parseInt(id),
      userId: parseInt(userID),
    });

    let data_updated = {
      content: {
        type: passCheck ? "APPROVAL" : "REJECTION",
        subject: subject,
        body: replaceFontClassesWithInlineStyles(description),
        assessmentId: parseInt(id),
        userId: parseInt(userID),
      },
      emailID: data?.data[0]?.id,
    };
    try {
      if (data?.data?.length === 0) {
        mutate(data_x);
      } else {
        updateMutate(data_updated);
      }
    } catch (err) {
      //react query will handle
    }
  };
  //#endregion
  return (
    <Transition.Root show={customizeCustomModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        initialFocus={cancelButtonRef}
        onClose={setCustomizeCustomModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={`fixed inset-0 bg-black ${
              emailOpacity ? "bg-opacity-75" : "bg-opacity-0"
            } transition-opacity`}
          />
        </Transition.Child>

        <ToastContainer
          position="top-center"
          transition={Zoom}
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          enableMultiContainer={false}
          limit={1}
        />
        <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left transition-all sm:my-8 sm:w-full md:w-2/4 h-auto md:h-[45rem]">
                <div className="bg-white">
                  <p className="text-xl font-bold text-black cursor-pointer text-left px-8 pt-5">
                    Edit invitation email
                  </p>
                  <IoClose
                    className="absolute top-3 right-5 z-20 w-5 h-5 cursor-pointer"
                    onClick={() => setCustomizeCustomModal(false)}
                  />
                </div>
                <hr className="w-full mt-8 bg-gray-500 border-1" />
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:h-[33rem] h-auto mt-5 pb-8">
                  <div className="sm:px-4 md:px-0 md:pl-8 pt-5">
                    <h1 className="font-bold text-lg mb-4">Email Content</h1>
                    <TextFieldCustom
                      id="subject"
                      type="text"
                      name="subject"
                      value={subject}
                      label="Subject"
                      onChangeValue={(e) => setSubject(e.target.value)}
                    />
                    <ReactQuill
                      theme="snow"
                      value={description}
                      onChange={setDescription}
                      className="md:h-[21.1rem] h-auto mt-4"
                      modules={Editor?.modules}
                    />
                  </div>
                  <div className="px-4 md:pr-8 pt-5">
                    <h1 className="font-bold text-lg">Preview</h1>
                    <div className="border border-neutral-300 px-2 py-3 rounded-tr overflow-auto rounded-tl mt-3.5">
                      <div style={{ whiteSpace: "nowrap" }}>
                        {ReactHtmlParser(
                          subject?.replace("{test_name}", assessmentData?.name)
                        )}
                      </div>
                    </div>
                    <div className="border border-neutral-300 border-t-0 px-2 py-3 h-[25rem] overflow-auto">
                      <table className="career_template w-full max-w-4xl bg-[#f6f9ff] mx-auto">
                        <thead>
                          <tr>
                            <th className="p-5">
                              {!userLoading && (
                                <>
                                  {userData?.data[0]?.companyAvatar != null ||
                                  userData?.data[0]?.companyAvatar != "" ? (
                                    <img
                                      className="w-15 h-14 mx-auto object-contain"
                                      src={userData?.data[0]?.companyAvatar}
                                      alt="logo"
                                    />
                                  ) : (
                                    <img
                                      className="w-15 h-14 mx-auto object-contain"
                                      src="https://cp-assessment.s3.eu-west-2.amazonaws.com/cp_assessment_logo.png"
                                      alt="logo"
                                    />
                                  )}
                                </>
                              )}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="p-3">
                              <div className=" bg-white rounded-lg px-2 py-6">
                                <div>
                                  {ReactHtmlParser(
                                    description
                                      ?.replace(
                                        /{candidate_name}/g,
                                        `${firstName} ${lastName}`
                                      )
                                      .replace(
                                        /{test_name}/g,
                                        assessmentData?.name
                                      )
                                      .replace(
                                        /{company_name}/g,
                                        userData?.data[0]?.companyName
                                      )
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="px-8 flex justify-between mt-5 flex-row w-full">
                  <div className="text-[#cb7b7a] my-auto"></div>
                  <div className="flex items-center gap-2">
                    <CustomButton
                      label="Cancel"
                      borderCustom="border border-black"
                      paddingY="0.3rem"
                      onClickButton={() => setCustomizeCustomModal(false)}
                    />
                    <div className="w-1/2">
                      <CustomButton
                        label="Save"
                        bgColor="#C0FF06"
                        textColor="#252E3A"
                        borderCustom="border border-black"
                        paddingY="0.45rem"
                        onClickButton={handleTemplateSubmit}
                      />
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
