import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  back: false,
};

export const BackModuleSlice = createSlice({
  name: "backModule",
  initialState,
  reducers: {
    setBackModuleToTrue: (state, action) => {
      state.back = true;
    },
    setBackModuleToFalse: (state, action) => {
      state.back = false;
    },
  },
});

export const { setBackModuleToTrue, setBackModuleToFalse } =
  BackModuleSlice.actions;
export default BackModuleSlice.reducer;
