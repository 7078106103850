import React, { useEffect, useState } from "react";
import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { getCandidateMetaData } from "../hooks/getCandidateMetaData";
import { getCandidateAssessments } from "../hooks/getCandidateAssessments";
import * as moment from "moment";
import { FaChevronRight } from "react-icons/fa";
import { getCandidateDetails } from "../hooks/getCandidateDetails";
import CustomButton from "../../../Components/CustomButton/CustomButton";
import ConfirmModal from "../../../Components/ConfirmationModals/ConfirmModal";
import confirm from "../../../Dexta_assets/confirm.png";
import { getEvaluationByAssessmentId } from "../hooks/getEvaluationByAssessmentId";

const Dashboard = () => {
  const navigate = useNavigate();
  const userID = localStorage.getItem("CP-CANDIDATE-ID");
  const [staticLoad, setStaticLoad] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [assessmentID, setAssessmentID] = useState(null);
  //#region useEffect for static loading and navigation
  useEffect(() => {
    setStaticLoad(true);
    setTimeout(() => {
      setStaticLoad(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (!metaLoading && !staticLoad) {
      if (metaError || metaData === undefined) {
        navigate("/candidate/profile");
      }
    }
  });
  //#endregion

  //#region Fetch Assessments Data and Candidate Meta Data
  const {
    data: metaData,
    isLoading: metaLoading,
    error: metaError,
  } = useQuery({
    queryKey: ["candidate-meta", userID],
    queryFn: () => getCandidateMetaData(userID),
    retry: false,
  });

  const {
    data: assessmentData,
    isLoading: assessmentLoading,
    error: assessmentError,
  } = useQuery({
    queryKey: ["candidate-assessments"],
    queryFn: () => getCandidateAssessments(),
    retry: false,
  });
  //#endregion

  //#region taking user to top of screen
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //#endregion

  const { data, error, isLoading } = useQuery(
    ["evaluation", assessmentID, userID],
    () => getEvaluationByAssessmentId(assessmentID, userID)
  );

  //#region Start user assessment
  const HandleStartAssessment = () => {
    const defaultColor = "#C0FF06";
    const defaultSecondaryColor = "#252E3A";
    const defaultAvatar =
      "https://dexta-public.s3.eu-west-2.amazonaws.com/dexta-logo.png";

    const theme = {
      logo: "",
      color: "",
      name: "",
      sec_color: "",
    };

    const section = {
      CurrentModule: 0,
      ModuleID: 0,
      time: 0,
      evaluation: data?.evaluation?.id,
      pageQuestion: 1,
      module_Name: "",
      isPractice: false,
    };
    const questions = { current: 0, total: 0, solved: 0 };
    if (data?.assessment?.user?.company?.color === "") {
      theme.color = defaultColor;
    } else if (data?.assessment?.user?.company?.color) {
      theme.color = data?.assessment?.user?.company?.color;
    }

    if (
      data?.assessment?.user?.company?.sec_color === "" ||
      data?.assessment?.user?.company?.sec_color === null
    ) {
      theme.sec_color = defaultSecondaryColor;
    } else if (data?.assessment?.user?.company?.sec_color) {
      theme.sec_color = data?.assessment?.user?.company?.sec_color;
    }

    if (data?.assessment?.user?.company?.companyAvatar === "") {
      theme.logo = defaultAvatar;
    } else if (data?.assessment?.user?.company?.companyAvatar) {
      theme.logo = data?.assessment?.user?.company?.companyAvatar;
    }
    theme.name = data?.assessment?.user?.company?.companyName;
    localStorage.setItem("theme", JSON.stringify(theme));
    localStorage.setItem("Current Module", JSON.stringify(section));
    localStorage.setItem("CANDIDATE-ASSESSMENT-ID", data?.assessment?.id);
    localStorage.setItem("questions", JSON.stringify(questions));
    navigate("/setup");
  };
  //#endregion

  return (
    <>
      <div className="md:mx-auto md:container sm:px-3 md:px-0">
        {metaLoading || staticLoad ? (
          <div class="loader-container-1 col-span-6">
            <div class="loader-1"></div>
          </div>
        ) : (
          <div className="sm:py-5 md:py-0 md:p-10 mt-5">
            <h1
              className="text-2xl font-medium"
              style={{ fontFamily: "Archia Semibold" }}
            >
              My results
            </h1>
            {assessmentData?.data?.length === 0 ? (
              <div className="mt-3">
                <div className="border border-[#FF5812] py-4 rounded">
                  <p
                    className="text-alertRed text-center"
                    style={{ fontFamily: "Silka Bold" }}
                  >
                    Your test results will be displayed here once you have taken
                    a test
                  </p>
                </div>
              </div>
            ) : (
              <>
                {assessmentData?.data?.map((i) => (
                  <div
                    className={`w-full ${
                      i?.status !== "completed" &&
                      i?.status !== "InProgress" &&
                      i?.status !== "invited" &&
                      i?.status !== "started"
                        ? "cursor-not-allowed"
                        : "cursor-pointer"
                    } mt-8`}
                    onClick={() => {
                      (i?.status === "completed" ||
                        i?.status === "InProgress" ||
                        i?.status === "invited" ||
                        i?.status === "started") &&
                        navigate(
                          `/candidate/assessment/${i?.assessments[0]?.id}`
                        );
                    }}
                  >
                    <div className="sm:p-4 md:p-8 rounded-lg bg-white group relative overflow-hidden">
                      {/* Background overlay with opacity */}
                      <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-10 transition-opacity duration-300"></div>
                      <div className="grid md:grid-cols-2 relative z-10">
                        <div className="flex flex-col">
                          <div className="flex sm:flex-col md:flex-row gap-3">
                            <h1
                              className="font-medium text-xl"
                              style={{ fontFamily: "Archia Semibold" }}
                            >
                              {i?.assessments[0]?.name}
                            </h1>
                            <div>
                              <p
                                className={`px-2 text-xs h-[18px] rounded-md mt-2 text-white inline-block 
      ${i?.status === "completed" && "bg-green-600"} 
      ${i?.status === "invited" && "bg-yellow-600"} 
      ${i?.status === "started" && "bg-gray-700"} 
      ${i?.status === "InProgress" && "bg-orange-600"}`}
                              >
                                {i?.status === "completed" && "Completed"}
                                {i?.status === "invited" && "Invited"}
                                {i?.status === "started" && "Started"}
                                {i?.status === "InProgress" && "In Progress"}
                              </p>
                            </div>
                          </div>

                          <p
                            className="text-gray-500 mt-7"
                            style={{ fontFamily: "Archia Semibold" }}
                          >
                            Company name:{" "}
                            <span className="text-black">
                              {i?.assessments[0]?.user?.companyName}
                            </span>
                          </p>
                          <div
                            className="flex sm:flex-col md:flex-row md:gap-10"
                            style={{ fontFamily: "Archia Semibold" }}
                          >
                            <p className="text-gray-500 sm:mt-3 md:mt-7">
                              Invited on:{" "}
                              <span className="text-black">
                                {moment(i.createdAt).format("Do MMMM, YYYY")}{" "}
                              </span>
                            </p>
                            <p className="text-gray-500 sm:mt-3 md:mt-7">
                              Last activity:{" "}
                              <span className="text-black">
                                {moment(i.updatedAt).fromNow()}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="ml-auto my-auto">
                          <FaChevronRight className="w-3 h-3" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        )}
      </div>
      {confirmModal && (
        <ConfirmModal
          setConfirmModal={setConfirmModal}
          icon={confirm}
          onClick={() => HandleStartAssessment()}
          header="Resume test"
          buttonText="Yes"
          description=" Are you sure you want to resume your test?"
        />
      )}
    </>
  );
};

export default Dashboard;
