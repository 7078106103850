import http from "../../../http";

const handleOpenEmailInboxButtonClick = () => {
  // Constructing the mailto link without any additional parameters
  const mailtoLink = "mailto:support@dexta.io";

  // Opening the default email client
  window.location.href = mailtoLink;
};

export const filters_tests = [
  {
    id: "category",
    name: "Experience Level",
    options: [
      {},
      { id: 1, value: "Beginner", label: "Entry Level" },
      { id: 2, value: "Intermediate", label: "Mid Level" },
      { id: 3, value: "Advanced", label: "Senior Level" },
      { id: 3, value: "All", label: "All Levels" },
    ],
  },
  {
    id: "industry",
    name: "Industry",
    options: [],
  },
  {
    id: "department",
    name: "Department",
    options: [],
  },
  {
    id: "job_role",
    name: "Job Role",
    options: [],
  },
];

export const test_duration = [
  { id: 1, value1: 0, value2: 10, label: "Up to 10 mins" },
  { id: 2, value1: 11, value2: 20, label: "11 - 20 mins" },
  { id: 3, value1: 21, value2: 30, label: "21-30 mins" },
  { id: 4, value1: 31, value2: 60, label: "31-60 mins" },
];

export const stepsTests = [
  {
    title: "Modules",
    element: "#tests2",
    intro: (
      <div>
        <p>Use this dashboard to view all available modules at a glance.</p>
        <p style={{ marginTop: "8px" }}>Here's what you can do:</p>
        <p>Each module will have two buttons:</p>
        <ul className="list-disc px-4">
          <li>
            <strong>Read More Button:</strong> Clicking this button will provide
            more information about the module, including its description,
            duration, and objectives
          </li>
          <li>
            <strong>Sample Questions Button:</strong> Clicking this button will
            display a selection of sample questions from the module, allowing
            you to preview the content
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: "Filter Modules",
    element: "#tests3",
    intro: (
      <div>
        <p>
          Use these filters to refine the list of modules according to your
          preferences:
        </p>
        <ul className="list-disc px-4">
          <li>
            <strong>Experience Level:</strong> Filter modules based on the
            experience level they are designed for, such as entry-level,
            intermediate, or advanced
          </li>
          <li>
            <strong>Industry:</strong> Narrow down the list by selecting
            specific industries relevant to the modules you're interested in,
            such as technology, healthcare, or finance
          </li>
          <li>
            <strong>Job Role:</strong> Customise the list further by filtering
            modules based on job roles, such as software developer, marketing
            manager, or sales representative
          </li>
          <li>
            <strong>Time Duration:</strong> Refine the list by specifying the
            duration of modules, whether short modules, moderate-length modules,
            or comprehensive evaluations
          </li>
        </ul>
      </div>
    ),
  },
  {
    intro: (
      <div>
        <p>
          Your product tour is now complete. If you have any other questions,
          we're here to help. Message us by{" "}
          <a
            href="mailto:support@dexta.io"
            className="font-bold cursor-pointer"
          >
            clicking here
          </a>
        </p>
      </div>
    ),
  },
];
